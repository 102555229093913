import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import PropTypes from 'prop-types';

const AdmicityBackdrop = ({open = false}) => {
    if (!open) {
        return null;
    }

    return (
        <>
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                sx={{
                    backgroundColor: 'rgb(250, 250, 251)',
                    zIndex: (theme) => theme.zIndex.drawer,
                    pointerEvents: 'none',
                }}
            />
            <Box
                position="fixed"
                top="50%"
                left="50%"
                sx={{
                    transform: 'translate(-50%, -50%)',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    pointerEvents: 'none'
                }}
            >
                <CircularProgress size={40}/>
            </Box>
        </>
    );
};

AdmicityBackdrop.propTypes = {
    open: PropTypes.bool.isRequired
};

export default AdmicityBackdrop;