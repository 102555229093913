import React, { useEffect, useState } from 'react';
import { Alert, Box, TextField, Typography, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import AdmicityGroupFilterSelect from '../../../../shared-components/AdmicityGroupFilterSelect';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import useTextField from '../../../../utility/hooks/useTextField';
import { validateFieldLength } from '../../../../utility/validationUtil';
import { useGetFormCategoriesQuery } from '../../../../api/services/formCategoriesService';
import { useGetGroupFiltersQuery } from '../../../../api/services/groupService';
import PropTypes from 'prop-types';
import {availableComponents} from '../FormBuilder/FormBuilder';
import {determinePossibleOperations} from '../FormBuilder/ShowFieldWhen';

const CreateGroupFromResponsesDialog = ({
                                            open,
                                            title,
                                            onClose,
                                            onSave,
                                            schema,
                                        }) => {
    const { data: groupFilters = { modules: [] }, refetch: refetchGroupFilters } = useGetGroupFiltersQuery({ fetchOnlyCustom: true });
    const nameField = useTextField({
        initialValue: '',
        validate: (value) => validateFieldLength(value, 256),
    });
    const { data: formCategories } = useGetFormCategoriesQuery();
    const [parentGroupFilterId, setParentGroupFilterId] = useState('');
    const [isGroupDynamic, setIsGroupDynamic] = useState(false);
    const [myGroup, setMyGroup] = useState(false);
    const [groupType, setGroupType] = useState('School Group');
    const [formCategoryId, setFormCategoryId] = useState(null);
    const [filteredGroupFilters, setFilteredGroupFilters] = useState([]);
    const [relatedComponent, setRelatedComponent] = useState(null);
    const [targetState, setTargetState] = useState(null);

    useEffect(() => {
        if (!open) {
            nameField.reset();
            setParentGroupFilterId('');
            setMyGroup(false);
            setGroupType('School Group');
            setFormCategoryId(null);
            setIsGroupDynamic(false);
            setRelatedComponent(null);
            setTargetState(null);
        }
    }, [open]);

    useEffect(() => {
        const filtered = groupFilters?.modules?.filter((group) =>
            group.name?.toLowerCase().includes(groupType.toLowerCase())
        ) || [];
        setFilteredGroupFilters(filtered);
    }, [groupFilters, groupType]);

    const handleGroupTypeChange = (e) => {
        const selectedGroupType = e.target.value;
        setMyGroup(selectedGroupType === 'My Group');
        setGroupType(selectedGroupType);
        setParentGroupFilterId('');
    };

    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value || null;
        setFormCategoryId(selectedCategoryId);
    };

    const handleSave = async () => {
        await onSave({
            parentGroupFilterId,
            groupName: nameField.value,
            myGroup,
            formCategoryId,
            isDynamic: isGroupDynamic,
            relatedComponentId: relatedComponent.id,
            targetStateId: targetState
        });

        refetchGroupFilters();
    };

    return (
        <AdmicityDialog
            handleClose={onClose}
            title={title}
            open={open}
            actions={[
                {
                    label: 'Cancel',
                    onClick: onClose,
                },
                {
                    label: 'Save',
                    disabled: nameField.error || !nameField.value || !relatedComponent || targetState === null,
                    onClick: handleSave,
                },
            ]}
        >
            <Alert severity="info">
                Please note that students will be assigned to the new group based on the filters applied to the form responses
            </Alert>
            <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                    required
                    fullWidth
                    label="Group Name"
                    variant="outlined"
                    margin="normal"
                    {...nameField}
                />
                <TextField
                    select
                    value={groupType}
                    onChange={handleGroupTypeChange}
                    label="Group Type"
                    fullWidth
                    helperText="Please select the type of group (cannot be changed after saving)"
                >
                    <MenuItem value="My Group">My Group</MenuItem>
                    <MenuItem value="School Group">School Group</MenuItem>
                </TextField>
                <TextField
                    select
                    value={formCategoryId || ''}
                    label="Category"
                    helperText="Please select a category or clear the selection"
                    fullWidth
                    onChange={handleCategoryChange}
                    SelectProps={{
                        renderValue: (selected) => {
                            if (!selected) return 'No category selected';
                            const selectedCategory = formCategories.find(
                                (category) => category.id === selected
                            );
                            if (!selectedCategory) return 'Invalid category selected';
                            return (
                                <Box display="flex" alignItems="center">
                                    <Box
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            borderRadius: '50%',
                                            backgroundColor: selectedCategory.color || 'grey',
                                            marginRight: 1,
                                        }}
                                    />
                                    {selectedCategory.name || ''}
                                </Box>
                            );
                        },
                    }}
                >
                    <MenuItem value="">
                        <Box display="flex" alignItems="center" sx={{ color: 'grey' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: 'transparent',
                                    marginRight: 1,
                                }}
                            />
                            No category
                        </Box>
                    </MenuItem>
                    {formCategories?.length > 0 &&
                        formCategories.map(({ id, name, color }) => (
                            <MenuItem key={id} value={id}>
                                <Box display="flex" alignItems="center">
                                    <Box
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            borderRadius: '50%',
                                            backgroundColor: color || 'grey',
                                            marginRight: 1,
                                        }}
                                    />
                                    {name}
                                </Box>
                            </MenuItem>
                        ))}
                </TextField>
                <Box display="flex" flexDirection="column" gap={2}>
                    <Box>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Parent Group
                        </Typography>
                        <Typography variant="subtitle2">Choose if you want to add this as a sub-group</Typography>
                    </Box>
                </Box>
                <AdmicityGroupFilterSelect
                    sx={{
                        marginTop: 1,
                        width: '100%',
                    }}
                    data={filteredGroupFilters}
                    value={parentGroupFilterId}
                    onChange={(nodeId, paths) => {
                        setParentGroupFilterId(nodeId, paths);
                    }}
                    isRequired={false}
                />
                <FormControlLabel
                    sx={{
                        marginTop: -2,
                        marginBottom: -2,
                    }}
                    control={
                        <Checkbox
                            onChange={(e) => setIsGroupDynamic(e.target.checked)}
                        />
                    }
                    label="Dynamic Group (auto-includes students)"
                />
                <TextField
                    select
                    value={relatedComponent || ''}
                    disabled={!schema}
                    label={'Question'}
                    required
                    onChange={e => setRelatedComponent(e.target.value)}
                >
                    {schema?.filter(option => availableComponents.includes(option.type))
                        .map(option =>
                            <MenuItem key={option.id} value={option} sx={{maxWidth: '550px'}}>
                                <div title={`${option.label} - ${option.description}`} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {option.label} - {option.description}
                                </div>
                            </MenuItem>)}
                </TextField>
                <TextField
                    select
                    value={targetState}
                    label={'Equals'}
                    required
                    disabled={!relatedComponent}
                    onChange={e => setTargetState(e.target.value)}
                >
                    {relatedComponent &&
                        determinePossibleOperations(relatedComponent)?.map(operation =>
                            <MenuItem key={operation.value} value={operation.value} sx={{maxWidth: '550px'}}>
                                <div title={operation.title} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {operation.title}
                                </div>
                            </MenuItem>)}
                </TextField>
            </Box>
        </AdmicityDialog>
    );
};

CreateGroupFromResponsesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
};

export default CreateGroupFromResponsesDialog;
