import {useGetAssignmentFormsQuery, useLazyGetFormQuery} from '../../../../api/services/formsService';
import React, {useEffect, useState} from 'react';
import {Box, Button, MenuItem, TextField} from '@mui/material';
import {availableComponents} from '../FormBuilder/FormBuilder';
import {determinePossibleOperations} from '../FormBuilder/ShowFieldWhen';
import {SettingsSection} from './SettingsSection';
import FORM_AUDIENCE_TYPE from '../../../../constants/FormAudienceType';
import EmailsInput from '../../../../shared-components/EmailsInput';
import {mergeArraysByKey} from '../../../../utility/mergeArraysByKey';

/* eslint-disable react/prop-types */

const triggerFormSettingsInitialState = {
    selectedForm: null,
    selectedFormSchema: null,
    relatedComponent: null,
    targetState: null
};

const TriggerFormSettings = (
    {
        formId,
        audience,
        settings,
        externalUsers,
        onSettingsChange
    }) => {
    const {
        externalUserEmailAssignments,
        triggerFormSettings
    } = settings;
    const [originalSettings, setOriginalSettings] = useState({
        externalUserEmailAssignments: [],
        triggerFormSettings: {}
    });
    const {data: forms = []} = useGetAssignmentFormsQuery(formId);
    const [getForm] = useLazyGetFormQuery();

    useEffect(() => {
        if (externalUsers) {
            onSettingsChange(prev => ({
                ...prev,
                externalUserEmailAssignments: externalUsers
            }));
            setOriginalSettings(prev => ({
                ...prev,
                externalUserEmailAssignments: externalUsers
            }));
        }
    }, [externalUsers]);

    return (
        <SettingsSection>
            <TextField
                required
                select
                fullWidth
                value={triggerFormSettings.selectedForm?.id || ''}
                label="Form"
                onChange={async e => {
                    const formId = e.target.value;
                    const getFormResponse = await getForm(formId);
                    const form = getFormResponse.data;

                    onSettingsChange(prev => ({
                        ...prev,
                        triggerFormSettings: {
                            ...triggerFormSettingsInitialState,
                            original: prev.triggerFormSettings.original,
                            selectedForm: {
                                id: form.id,
                                name: form.name,
                                schema: form.schema
                            },
                        }
                    }));
                }}
            >
                {
                    forms?.map(({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)
                }
            </TextField>
            <TextField
                select
                value={triggerFormSettings.relatedComponent || ''}
                disabled={!triggerFormSettings.selectedForm?.schema}
                label={'Question'}
                required
                onChange={e =>
                    onSettingsChange(prev => ({
                        ...prev,
                        triggerFormSettings: {
                            ...triggerFormSettingsInitialState,
                            ...prev.triggerFormSettings,
                            relatedComponent: e.target.value
                        }
                    }))}
            >
                {triggerFormSettings.selectedForm?.schema?.filter(option => availableComponents.includes(option.type))
                    .map(option =>
                        <MenuItem key={option.id} value={option}>
                            {option.label} - {option.description}
                        </MenuItem>)}
            </TextField>
            <TextField
                select
                value={triggerFormSettings.targetState}
                label={'Equals'}
                required
                disabled={!triggerFormSettings.relatedComponent}
                onChange={e =>
                    onSettingsChange(prev => ({
                        ...prev,
                        triggerFormSettings: {
                            ...prev.triggerFormSettings,
                            targetState: e.target.value
                        }
                    }))}
            >
                {triggerFormSettings.relatedComponent &&
                    determinePossibleOperations(triggerFormSettings.relatedComponent)?.map(operation =>
                        <MenuItem key={operation.value} value={operation.value}>
                            {operation.title}
                        </MenuItem>)}
            </TextField>
            {
                audience === FORM_AUDIENCE_TYPE.externalUsers
                    ? <EmailsInput
                        values={externalUserEmailAssignments.filter(x => !x.isDeleted)}
                        onChange={emails => onSettingsChange(prev => ({
                            ...prev,
                            externalUserEmailAssignments: mergeArraysByKey(
                                emails,
                                originalSettings.externalUserEmailAssignments,
                                'email')
                        }))}
                        onBlur={email => onSettingsChange(prev => ({
                            ...prev,
                            externalUserEmailAssignments: mergeArraysByKey(
                                [...settings.externalUserEmailAssignments, {email}],
                                originalSettings.externalUserEmailAssignments,
                                'email')
                        }))}
                    />
                    : null
            }
            <Box textAlign="end">
                <Button
                    variant="outlined"
                    onClick={() => onSettingsChange(prev => ({
                        ...prev,
                        triggerFormSettings: {
                            ...triggerFormSettingsInitialState,
                            original: prev.triggerFormSettings.original,
                        }
                    }))}>Clear trigger settings</Button>
            </Box>
        </SettingsSection>
    );
};

export default TriggerFormSettings;