import React, {useEffect, useState} from 'react';
import {Badge, Box, Button, Divider, Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import {generateUUID} from '../../../../utility/uuidUtil';
import PropTypes from 'prop-types';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {getComponentByType} from './FormBuilderComponents';
import {FORM_BUILDER_COMPONENTS, FormBuilderComponentTypes} from './ComponentList';
import ComponentBuilder from './Components/ComponentBuilder';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import ShowFieldWhen from './ShowFieldWhen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmRemoveDialog from './ConfirmRemoveDialog';
import {useGetRelatedFormQuestionAssignmentQuery} from '../../../../api/services/formAssignmentsService';
import Tooltip from '@mui/material/Tooltip';
import {getRole} from '../../../../utility/jwtUtil';
import {ROLES} from '../../../../constants/roles';
import {useSelector} from 'react-redux';
import ImageWithConditionalRender from '../../../../shared-components/ImageWithConditionalRender';
import ErrorIcon from '@mui/icons-material/Error';

export const availableComponents = [
    FormBuilderComponentTypes.Dropdown,
    FormBuilderComponentTypes.SingleChoice,
    FormBuilderComponentTypes.CheckboxQuestion,
    FormBuilderComponentTypes.MultipleChoice,
    FormBuilderComponentTypes.OfferStatusChoice,
];

const MAX_FILE_UPLOADER_COMPONENTS_COUNT = 5;

const FormBuilder = ({
                         schema,
                         setSchema,
                         submitMode = false,
                         handleSubmit,
                         handleDownloadAttachment,
                         data,
                         isParent,
                         readOnly,
                         isFormArchived,
                         placeholderValues,
                         accessLevel,
                         formId,
                         componentToEditIndex,
                         setComponentToEditIndex
                     }) => {
    const [previewMode, setPreviewMode] = useState(false);
    const [submitData, setSubmitData] = useState({});
    const [currentComponent, setCurrentComponent] = useState(undefined);
    const [relatedComponents, setRelatedComponents] = useState([]);
    const [componentToConfirmRemove, setComponentToConfirmRemove] = useState(undefined);
    const [formPlaceholders, setFormPlaceholders] = useState(undefined);
    const {account} = useSelector(state => state.application);

    const {data: relatedFormQuestionAssignData} = useGetRelatedFormQuestionAssignmentQuery(formId, {skip: submitMode || !formId});

    useEffect(() => {
        setRelatedComponents(schema.filter(e => availableComponents.includes(e.type) && e.id !== currentComponent?.id));
    }, [schema]);

    useEffect(() => {
        if (submitMode) {
            const newPlaceholders = {...placeholderValues};
            if (!placeholderValues.parent && (getRole() === ROLES.PARENT || isParent)) {
                newPlaceholders.parent = `${account.firstName} ${account.lastName}`;
            }

            setFormPlaceholders(newPlaceholders);
        }
    }, [placeholderValues]);

    const handleChangeEdit = ({
                                  index,
                                  description,
                                  validation,
                                  enableValidation,
                                  option,
                                  optionId,
                                  isAdd,
                                  isRemove,
                                  files,
                                  listKey,
                                  startFrom,
                                  endAt,
                                  startLabel,
                                  endLabel,
                                  formattedDescription
                              }) => {
        const newForm = [...schema];
        const updatedItem = structuredClone(newForm[index]);

        if (description !== undefined) {
            updatedItem.description = description;
        }
        if (formattedDescription !== undefined) {
            updatedItem.formattedDescription = formattedDescription;
        }
        if (validation !== undefined) {
            updatedItem.validation = validation;
        }
        if (enableValidation !== undefined) {
            updatedItem.enableValidation = enableValidation;
        }
        if (option !== undefined && !listKey) {
            const optIndex = updatedItem.options.findIndex(e => e.id === optionId);
            if (optIndex !== -1) {
                const opt = {...updatedItem.options[optIndex]};
                opt.description = option.description;

                if (Object.prototype.hasOwnProperty.call(option, 'value')) {
                    opt.value = option.value;
                }
                const options = [...updatedItem.options];
                options[optIndex] = opt;
                updatedItem.options = options;
            }
        }
        if (isAdd && !listKey) {
            updatedItem.options = [
                ...updatedItem.options,
                {id: generateUUID()}
            ];
        }
        if (isRemove && !listKey) {
            const relatedComponent = schema.find(e => e.relates && e.relates.targetState === optionId);
            if (relatedComponent) {
                setComponentToConfirmRemove({
                    component: relatedComponent,
                    handleRemove: () => removeOptionModal(index, optionId)
                });
            } else {
                updatedItem.options = updatedItem.options.filter(e => e.id !== optionId);
            }
        }
        if (listKey) {
            if (isRemove) {
                updatedItem[listKey] = updatedItem[listKey].filter((_, idx) => idx !== optionId);
            } else if (isAdd) {
                updatedItem[listKey] = [...updatedItem[listKey], {title: '', id: generateUUID()}];
            } else {
                updatedItem[listKey][optionId].title = option;
            }
        }
        if (files !== undefined) {
            updatedItem.attachments = files;
        }
        if (startFrom !== undefined) {
            updatedItem.startFrom = startFrom;
        }
        if (endAt !== undefined) {
            updatedItem.endAt = endAt;
        }
        if (startLabel !== undefined) {
            updatedItem.startLabel = startLabel;
        }
        if (endLabel !== undefined) {
            updatedItem.endLabel = endLabel;
        }

        newForm[index] = updatedItem;
        setSchema(newForm);
    };

    const handleShowFieldWhen = (id, relatesTo, targetState) => {
        handleDialogClose();
        const newForm = JSON.parse(JSON.stringify(schema));
        let updatedComponent = newForm.find(e => e.id === id);

        if (relatesTo && targetState !== undefined) {
            updatedComponent.relates = {relatesTo, targetState};
        } else {
            delete updatedComponent.relates;
        }

        setSchema(newForm);
    };

    const handleChangeSubmit = ({id, value, optionIndex, optionValue, radioValue}) => {
        if (readOnly) return;

        const newSubmitData = {...submitData};
        if (value !== undefined) {
            newSubmitData[id] = value;

        } else if (optionIndex !== undefined && optionValue !== undefined) {
            if (!newSubmitData[id]) {
                newSubmitData[id] = [];
            }

            newSubmitData[id] = optionValue ? [...newSubmitData[id], optionIndex] : newSubmitData[id].filter(o => o !== optionIndex);
            if (newSubmitData[id].length === 0) {
                newSubmitData[id] = undefined;
            }
        } else if (radioValue !== undefined) {
            newSubmitData[id] = radioValue;
        }

        setSubmitData((data) => ({...data, ...newSubmitData}));
    };

    const removeComponent = (position) => {
        let componentToRemove = schema.find((e, index) => index === position);
        const relatedComponents = schema.filter(e => e.relates && e.relates.relatesTo === componentToRemove.id);

        if (relatedComponents.length > 0) {
            setComponentToConfirmRemove({
                components: relatedComponents,
                handleRemove: () => removeComponentModal(componentToRemove.id)
            });
        } else {
            setSchema(schema.filter((e, index) => index !== position));
        }
    };

    const removeComponentModal = (id) => {
        const newForm = JSON.parse(JSON.stringify(schema));
        newForm.forEach(e => {
            if (e.relates && e.relates.relatesTo === id) {
                delete e.relates;
            }
        });
        setSchema(newForm.filter((e) => e.id !== id));
        setComponentToConfirmRemove(undefined);
    };

    const removeOptionModal = (index, optionId) => {
        const newForm = JSON.parse(JSON.stringify(schema));
        newForm.forEach(e => {
            if (e.relates && e.relates.targetState === optionId) {
                delete e.relates;
            }
        });
        newForm[index].options = newForm[index].options.filter(e => e.id !== optionId);
        setSchema(newForm);
        setComponentToConfirmRemove(undefined);
    };

    const moveComponent = (position, swapPosition) => {
        let newArray = [...schema];
        let temp = newArray[position];
        newArray[position] = newArray[swapPosition];
        newArray[swapPosition] = temp;
        setSchema(newArray);
    };

    const onDragEnd = result => {
        setComponentToEditIndex(undefined);
        if (!result.destination) {
            return;
        }

        moveComponent(result.source.index, result.destination.index);
    };

    const addComponent = (type) => {
        const newComponent = {
            ...getComponentByType(type),
            enableValidation: type === 'signature',
            id: generateUUID()
        };

        const filteredSchema = schema.filter(component => component.type !== 'schoolLogo');

        return setSchema([
            ...filteredSchema,
            newComponent,
            ...schema.filter(component => component.type === 'schoolLogo')
        ]);
    };

    const checkFailedValidation = (field) => {
        if (field.relates) {
            const relatedComponent = schema.find(e => field.relates.relatesTo === e.id);
            if (Array.isArray(submitData[relatedComponent.id])
                ? !submitData[relatedComponent.id].includes(field.relates.targetState)
                : submitData[relatedComponent.id] !== field.relates.targetState
            ) {
                return false;
            }
        }

        if (field.type === FormBuilderComponentTypes.TickBoxGrid || field.type === FormBuilderComponentTypes.MultipleChoiceGrid) {
            const data = submitData[field.id];
            if (!data) return true;

            for (let rowIndex = 0; rowIndex < field.rows.length; rowIndex++) {
                const key = field.rows[rowIndex];

                if (!data[key.id] || data[key.id].length === 0) {
                    return true;
                }
            }
            return false;
        }

        if (field.type === FormBuilderComponentTypes.CheckboxQuestion ||
            field.type === FormBuilderComponentTypes.SingleChoice ||
            field.type === FormBuilderComponentTypes.OfferStatusChoice) {
            return !submitData[field.id];
        }

        return submitData[field.id] === undefined || submitData[field.id] === '';
    };

    const handleDialogClose = () => {
        setCurrentComponent(undefined);
    };
    const buildEditRows = (f, index) => {
        const relatedComponent = relatedFormQuestionAssignData?.find(e => e.relatedComponentId === f.id);
        return <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={1}>
            <Typography>{index + 1}. {f.label}</Typography>
            <Box display={'flex'} alignItems={'center'}>
                {!isFormArchived &&
                    <IconButton>
                        <EditIcon onClick={() => setComponentToEditIndex(index)}/>
                    </IconButton>
                }
                {!isFormArchived &&
                    <Tooltip
                        title={relatedComponent
                            ? `This question cannot be deleted as it is currently used in the ${relatedComponent?.relatedFormName} form.`
                            : null}>
                        <IconButton>
                            <DeleteIcon
                                onClick={() => relatedComponent ? {} : removeComponent(index)}/>
                        </IconButton>
                    </Tooltip>
                }
                {!isFormArchived && relatedComponents.filter(c => c.id !== f.id).length > 0 &&
                    <IconButton>
                        <VisibilityIcon color={f.relates ? 'primary' : ''} onClick={() => setCurrentComponent(f)}/>
                    </IconButton>
                }
                {index !== 0 && !isFormArchived &&
                    <IconButton>
                        <ArrowUpwardOutlinedIcon onClick={() => moveComponent(index, index - 1)}/>
                    </IconButton>
                }
                {index !== schema.length - 2 && !isFormArchived &&
                    <IconButton>
                        <ArrowDownwardOutlinedIcon onClick={() => moveComponent(index, index + 1)}/>
                    </IconButton>
                }
            </Box>
        </Box>;
    };

    const checkIfVisible = (relates) => {
        if (relates) {
            const relatedComponent = readOnly ? data[relates.relatesTo] : submitData[relates.relatesTo];
            return Array.isArray(relatedComponent)
                ? relatedComponent.includes(relates.targetState)
                : relatedComponent === relates.targetState || (relates.targetState === false && relatedComponent === undefined);
        }
        return true;
    };

    const schoolLogoComponent = schema.find(x => x.type === 'schoolLogo');

    return (
        submitMode
            ? <Box display="flex" flexDirection="column" gap={2}>
                {
                    schoolLogoComponent?.url
                        ? <Box textAlign="center">
                            <ImageWithConditionalRender imageUrl={schoolLogoComponent?.url} alt="School logo"/>
                        </Box>
                        : ''
                }
                {schema?.filter(e => checkIfVisible(e.relates))
                    .map((f, index) =>
                        f.type !== 'schoolLogo'
                            ? <Box key={`formItem-${index}`}>
                                <ComponentBuilder handleChangeSubmit={handleChangeSubmit}
                                                  handleDownloadAttachment={handleDownloadAttachment}
                                                  index={index} readOnly={readOnly}
                                                  handleChangeEdit={handleChangeEdit}
                                                  previewMode={componentToEditIndex === index || previewMode}
                                                  field={f}
                                                  componentToEditIndex={componentToEditIndex}
                                                  data={data} submitMode={submitMode}
                                                  submitData={submitData} isFormArchived={isFormArchived}
                                                  placeholderValues={formPlaceholders}/>
                            </Box>
                            : '')
                }
                {
                    <Box>
                        {!readOnly && !previewMode && <Divider/>}
                        <Box display="flex" justifyContent="end" marginTop={2}>
                            {
                                readOnly || previewMode
                                    ? null
                                    : <Button
                                        variant="contained"
                                        onClick={() => handleSubmit(submitData, JSON.stringify(formPlaceholders))}
                                        disabled={schema.some(field => field.enableValidation && (checkFailedValidation(field)))}>
                                        Submit
                                    </Button>
                            }
                        </Box>
                    </Box>
                }
            </Box>
            : <Box onDoubleClick={() => submitMode ? () => {} : setComponentToEditIndex(undefined)}>
                <Box display={'flex'} justifyContent={'end'}>{!submitMode &&
                    <Button onClick={() => setPreviewMode(!previewMode)} sx={{width: '100px'}}>
                        {previewMode ? 'Edit' : 'Preview'}
                    </Button>}
                </Box>
                <Box display={'flex'} fullWidth justifyContent={'center'}>
                    <Paper
                        variant={'outlined'}
                        sx={{
                            flex: 2,
                            p: 2,
                            mx: previewMode ? '25%' : '20%',
                            mb: 2
                        }}
                    >
                        {
                            schoolLogoComponent?.url
                                ? <Box textAlign="center">
                                    <ImageWithConditionalRender imageUrl={schoolLogoComponent?.url} alt="School logo"/>
                                </Box>
                                : ''
                        }
                        {!previewMode
                            ? <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <Box
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {schema?.map((f, index) =>
                                                f.type !== 'schoolLogo'
                                                    ? <Draggable key={index} draggableId={index.toString()}
                                                                 index={index}
                                                                 isDragDisabled={isFormArchived}>
                                                        {(provided) => (
                                                            <Box
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}>
                                                                <Paper sx={{m: '0 20px 10px 20px', p: 3}} variant="outlined"
                                                                       key={`formItem-${index}-${f.description}`}>
                                                                    {buildEditRows(f, index)}
                                                                    <ComponentBuilder
                                                                        handleChangeSubmit={handleChangeSubmit}
                                                                        index={index} readOnly={readOnly}
                                                                        handleChangeEdit={handleChangeEdit}
                                                                        previewMode={componentToEditIndex !== index || previewMode}
                                                                        field={f}
                                                                        componentToEditIndex={componentToEditIndex}
                                                                        data={data} submitMode={submitMode}
                                                                        submitData={submitData}
                                                                        isFormArchived={isFormArchived}
                                                                        accessLevel={accessLevel}
                                                                        relatedComponent={relatedFormQuestionAssignData
                                                                            ?.find(e => e.relatedComponentId === f.id)}/>
                                                                </Paper>
                                                            </Box>)}
                                                    </Draggable>
                                                    : '')}
                                            {provided.placeholder}
                                        </Box>)}
                                </Droppable>
                            </DragDropContext>
                            : schema?.filter(e => checkIfVisible(e.relates))
                                .map((f, index) =>
                                    f.type !== 'schoolLogo'
                                        ? <Box key={`formItem-${index}`} px={2} py={1}>
                                            <ComponentBuilder handleChangeSubmit={handleChangeSubmit}
                                                              handleDownloadAttachment={handleDownloadAttachment}
                                                              index={index} readOnly={readOnly}
                                                              handleChangeEdit={handleChangeEdit}
                                                              previewMode={componentToEditIndex === index || previewMode}
                                                              field={f}
                                                              componentToEditIndex={componentToEditIndex}
                                                              data={data} submitMode={submitMode}
                                                              submitData={submitData} isFormArchived={isFormArchived}
                                                              placeholderValues={formPlaceholders}/>
                                        </Box>
                                        : '')
                        }
                        {!readOnly && !previewMode && <Divider/>}
                        <Box display={'flex'} alignItems={'center'} justifyContent={submitMode ? 'end' : 'start'}
                             gap={1}
                             flexWrap={'wrap'} pt={1}>
                            {!previewMode
                                ? FORM_BUILDER_COMPONENTS.map((component, index) => {
                                    if (!isFormArchived &&
                                        component.type === FormBuilderComponentTypes.FileUploader &&
                                        schema.filter(component => component.type === FormBuilderComponentTypes.FileUploader).length >= MAX_FILE_UPLOADER_COMPONENTS_COUNT) {
                                        return (
                                            <Badge
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        top: 5,
                                                        padding: '0 4px',
                                                    },
                                                }}
                                                key={`${component.type}-${index}`}
                                                badgeContent={<Tooltip
                                                    title="Maximum of 5 file upload fields allowed on the form">
                                                    <ErrorIcon color="warning"/>
                                                </Tooltip>}
                                            >
                                                <Button
                                                    disabled
                                                    variant="outlined"
                                                >
                                                    {component.description}
                                                </Button>
                                            </Badge>
                                        );
                                    }

                                    return (
                                        <Button
                                            disabled={isFormArchived}
                                            key={`${component.type}-${index}`}
                                            variant={'outlined'}
                                            onClick={() => addComponent(component.type)}>
                                            {component.description}
                                        </Button>
                                    );
                                })
                                : readOnly || previewMode
                                    ? null
                                    : <Button onClick={() => {
                                        handleSubmit(submitData, JSON.stringify(formPlaceholders));
                                    }}
                                              disabled={schema.some(field => field.enableValidation && (checkFailedValidation(field)))}>
                                        Submit
                                    </Button>}
                        </Box>
                    </Paper>
                    <AdmicityDialog
                        handleClose={handleDialogClose}
                        title={'Show Field When'}
                        actions={[]}
                        open={Boolean(currentComponent)}
                        maxWidth={'md'}
                    >
                        <ShowFieldWhen component={currentComponent} handleShowFieldWhen={handleShowFieldWhen}
                                       availableComponents={relatedComponents.filter(c => c.id !== currentComponent?.id)}/>
                    </AdmicityDialog>
                    <ConfirmRemoveDialog
                        open={Boolean(componentToConfirmRemove)}
                        handleClose={() => setComponentToConfirmRemove(undefined)}
                        handleConfirm={componentToConfirmRemove?.handleRemove}
                        relatedComponents={componentToConfirmRemove?.components}/>
                </Box>
            </Box>);
};

FormBuilder.propTypes = {
    schema: PropTypes.array,
    setSchema: PropTypes.func,
    submitMode: PropTypes.bool,
    handleSubmit: PropTypes.func,
    handleDownloadAttachment: PropTypes.func,
    data: PropTypes.object,
    isParent: PropTypes.bool,
    readOnly: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    accessLevel: PropTypes.number,
    placeholderValues: PropTypes.object,
    formId: PropTypes.number,
    componentToEditIndex: PropTypes.number,
    setComponentToEditIndex: PropTypes.func
};

export default FormBuilder;