import React, {useEffect, useRef, useState} from 'react';
import {Box, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import PropTypes from 'prop-types';
import EditMultiFieldsBuilder from './Shared/EditMultiFieldsBuilder';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';

const SingleChoice = ({
                          index,
                          id,
                          value,
                          onChange,
                          description,
                          isEditMode,
                          enableValidation,
                          options,
                          isFormArchived,
                          relatedComponent,
                          readonly,
                          placeholderValues,
                          accessLevel,
                          onUpdateDescription,
                          componentToEditIndex,
                      }) => {

    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    return (
        <Box>
            {isEditMode
                ? <EditMultiFieldsBuilder onChange={onChange} enableValidation={enableValidation}
                                          description={description} index={index} options={options}
                                          isFormArchived={isFormArchived} relatedComponent={relatedComponent}
                                          accessLevel={accessLevel} onUpdateDescription={onUpdateDescription}
                                          componentToEditIndex={componentToEditIndex} currentDescription={currentValue}
                                          setCurrentDescription={setCurrentValue}/>
                : <Box key={`${description}-${value}`}>
                    <RichEditorResultText id={id} description={description} placeholderValues={placeholderValues}
                                          readonly={readonly} enableValidation={enableValidation}/>
                    <RadioGroup
                        value={value}
                        onClick={e => onChange({
                            id,
                            radioValue: e.target.value === value ? null : e.target.value
                        })}
                    >
                        {options.map((option) =>
                            <FormControlLabel
                                key={`${description}-option-${option.index}`}
                                sx={{
                                    '& .MuiTypography-root': {
                                        wordBreak: 'break-word',
                                        maxWidth: '800px',
                                    }
                                }}
                                value={option.id}
                                control={<Radio size={'small'}/>}
                                label={option.description}
                            />
                        )}
                    </RadioGroup>
                </Box>
            }
        </Box>
    );
};

SingleChoice.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    options: PropTypes.array,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    relatedComponent: PropTypes.object,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number
};

export default SingleChoice;