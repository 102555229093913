import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Paper, Stack} from '@mui/material';
import api from '../../axios';
import {useLocation, useNavigate} from 'react-router-dom';
import {validateFieldLength} from '../../utility/validationUtil';
import AdmicityForm from '../../shared-components/AdmicityForm';
import {ROLE_BASE_ROUTE} from '../../constants/roles';
import useUser from '../../utility/hooks/useUser';
import {getSubdomain} from '../../utility/routeUtil';
import {useGetSchoolInvitesInfoQuery} from '../../api/services/schoolManagementService';

const LoginPage = () => {
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();
    const {state: locationState} = useLocation();
    const {user, onLogin, onLogout} = useUser();

    const {
        data: invitesInfo = {
            disableStudentInvitations: true,
            disableParentInvitations: true,
        },
    } = useGetSchoolInvitesInfoQuery(getSubdomain(),
        {skip: !getSubdomain()}
    );

    useEffect(() => {
        if (user.id) {
            api.post('/account/session/validate')
                .then(response => {
                    if (response.data) {
                        if (user.domain != null) {
                            const path = locationState?.redirectTo
                                ? locationState?.redirectTo
                                : ROLE_BASE_ROUTE[user.role];
                            window.location.href = `https://${user.domain}${path}`;
                        } else {
                            navigate(ROLE_BASE_ROUTE[user.role], {replace: true});
                        }
                    }
                })
                .catch(_ => onLogout());
        }
    }, [user.id]);

    const handleLogin = async (payload) => {
        try {
            await api.post('/account/login', payload);
            onLogin();
        } catch (e) {
            if (e?.response?.status === 401) {
                setLoginError('Incorrect username or password.');
            } else {
                setLoginError('Something went wrong. Please try again later.');
            }
        }
    };

    const textFields = [
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            validate: (value) => validateFieldLength(value, 320)
        },
        {
            label: 'Password',
            type: 'password',
            name: 'password',
        }
    ];

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100%"
            flexGrow={1}
        >
            <Container maxWidth="xs">
                <Paper
                    variant="outlined"
                    sx={{p: 3, display: 'flex', flexDirection: 'column'}}
                    onClick={() => setLoginError('')}
                >
                    <AdmicityForm
                        title="Log in"
                        textFields={textFields}
                        handleSubmit={handleLogin}
                        handleChange={() => setLoginError('')}
                        buttonText="Log In"
                        errorCaption={loginError}
                    />
                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Button variant={'text'}
                                sx={{textTransform: 'none'}}
                                onClick={() => {
                                    navigate('/password-recovery');
                                }}>
                            Forgot password?
                        </Button>
                        {(!invitesInfo.disableParentInvitations || !invitesInfo.disableStudentInvitations) &&
                            <Button variant={'text'}
                                    sx={{textTransform: 'none'}}
                                    onClick={() => {
                                        navigate('/invite-recovery');
                                    }}>
                                Regenerate invite
                            </Button>}
                    </Stack>
                </Paper>
            </Container>
        </Box>
    );
};

export default LoginPage;
