import React from 'react';
import Footer from './Footer';
import Header from './Header';
import PropTypes from 'prop-types';
import {Box, styled} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {logout} from './ApplicationSlice';
import {useDispatch} from 'react-redux';
import GlobalSnackbar from '../shared-components/GlobalSnackbar';
import useUser from '../utility/hooks/useUser';

const AppLayoutContainer = styled(Box)({
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
});

const MainContainer = styled('main')(({theme}) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    minHeight: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(250, 250, 251)'
}));

const AppLayout = ({children}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user, onLogout} = useUser();
    const isEmbedded = searchParams.get('embedded') === 'true';

    const handleLogout = () => {
        dispatch(logout())
            .then(_ => {
                navigate('/login');
                onLogout();
            });
    };

    return (
        <AppLayoutContainer>
            {!isEmbedded && <Header displayActions={user?.id !== undefined} handleLogout={handleLogout}/>}
            <MainContainer>
                {children}
            </MainContainer>
            {!isEmbedded && <Footer/>}
            <GlobalSnackbar/>
        </AppLayoutContainer>
    );
};

AppLayout.propTypes = {
    children: PropTypes.object.isRequired,
};

export default AppLayout;