import {apiService} from '../apiService';
import {formatDate} from '../../utility/dateUtil';

export const studentService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getDietaryNeeds: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/special-dietary-needs`
                }),
                providesTags: (_) => [{type: 'DietaryNeeds', id: 'LIST'}]
            }),
            manageDietaryNeeds: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/special-dietary-needs`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'DietaryNeeds', id: 'LIST'}]
            }),
            getWelfare: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/welfare`
                }),
                providesTags: (_) => [{type: 'Welfare', id: 'LIST'}]
            }),
            manageWelfare: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/welfare`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'Welfare', id: 'LIST'}]
            }),
            getFreeSchoolMeals: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/free-meals`
                }),
                providesTags: (_) => [{type: 'FreeSchoolMeals', id: 'LIST'}]
            }),
            manageFreeSchoolMeals: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/free-meals`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'FreeSchoolMeals', id: 'LIST'}]
            }),
            getMedicalInfo: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/medical`
                }),
                providesTags: (_) => ['MedicalInfo']
            }),
            manageMedicalSection: builder.mutation({
                query: ({
                            studentId,
                            medicalConditions,
                            gpDetails,
                            vaccination,
                            deletedVaccinationFileId,
                            selectedConsents,
                            deselectedConsents,
                            medicalConditionStatus,
                            notIncludedChecked,
                            medicalNotesToDelete,
                            medicalNotesToAdd,
                            medicalNotesToEdit,
                            medicalEventsToDelete,
                            medicalEventsToAdd,
                            medicalEventsToEdit
                        }) => {
                    const formData = new FormData();
                    formData.append('studentId', studentId);

                    if (vaccination !== null) {
                        formData.append('vaccination', vaccination);
                    }

                    if (medicalConditionStatus !== null) {
                        formData.append('medicalConditionStatus', medicalConditionStatus);
                    }

                    if (notIncludedChecked !== null) {
                        formData.append('conditionNotIncluded', notIncludedChecked);
                    }

                    if (gpDetails !== null) {
                        formData.append('gpDetails', gpDetails === '' ? 'EMPTY' : gpDetails);
                    }

                    if (deletedVaccinationFileId) {
                        formData.append('deletedVaccinationFileId', deletedVaccinationFileId);
                    }

                    selectedConsents?.forEach((id, index) => {
                        formData.append(`selectedMedicalConsents[${index}]`, id);
                    });

                    deselectedConsents?.forEach((id, index) => {
                        formData.append(`deselectedMedicalConsents[${index}]`, id);
                    });

                    medicalConditions?.forEach((note, index) => {
                        formData.append(`MedicalConditions[${index}].medicalInfoId`, note.medicalInfoId);
                        if (note.medicalConditionId) {
                            formData.append(`MedicalConditions[${index}].medicalConditionId`, note?.id);
                        }

                        if (note.documents) {
                            note.documents.forEach((document, docIndex) => {
                                if (document.id !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].Id`, document.id);
                                }
                                if (document.fileId !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].FileId`, document.fileId);
                                }
                                if (document.summary !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].Summary`, document.summary);
                                }
                                if (document.note !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].Note`, document.note);
                                }
                                if (document.file !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].File`, document.file);
                                }

                                if (document.isActive !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].IsActive`, document.isActive);
                                }
                            });
                        }

                        formData.append(`MedicalConditions[${index}].isActive`, note.isActive);
                    });

                    medicalNotesToDelete?.forEach((id, index) => {
                        formData.append(`MedicalNotesToDelete[${index}]`, id);
                    });

                    medicalNotesToAdd?.forEach((note, index) => {
                        if (note.file !== undefined) {
                            formData.append(`MedicalNotesToAdd[${index}].File`, note.file);
                        }
                        if (note.note !== undefined) {
                            formData.append(`MedicalNotesToAdd[${index}].Note`, note.note);
                        }
                        if (note.summary !== undefined) {
                            formData.append(`MedicalNotesToAdd[${index}].Summary`, note.summary);
                        }
                    });

                    medicalNotesToEdit?.forEach((note, index) => {
                        if (note.id !== undefined) {
                            formData.append(`MedicalNotesToEdit[${index}].Id`, note.id);
                        }
                        if (note.file !== undefined) {
                            formData.append(`MedicalNotesToEdit[${index}].File`, note.file);
                        }
                        if (note.fileId) {
                            formData.append(`MedicalNotesToEdit[${index}].FileId`, note.fileId);
                        }
                        if (note.note !== undefined) {
                            formData.append(`MedicalNotesToEdit[${index}].Note`, note.note);
                        }
                        if (note.summary !== undefined) {
                            formData.append(`MedicalNotesToEdit[${index}].Summary`, note.summary);
                        }
                    });

                    medicalEventsToDelete?.forEach((id, index) => {
                        formData.append(`MedicalEventsToDelete[${index}]`, id);
                    });

                    medicalEventsToAdd?.forEach((event, index) => {
                        formData.append(`MedicalEventsToAdd[${index}].EventId`, event.eventId);
                        formData.append(`MedicalEventsToAdd[${index}].EventTypeId`, event.eventTypeId);
                        if (event.followUpDate) {
                            formData.append(`MedicalEventsToAdd[${index}].FollowUpDate`, formatDate(event.followUpDate));
                        }
                        if (event.eventDate) {
                            formData.append(`MedicalEventsToAdd[${index}].EventDate`, formatDate(event.eventDate));
                        }

                        event.documents?.forEach((document, docIndex) => {
                            if (document.file !== undefined) {
                                formData.append(`MedicalEventsToAdd[${index}].Documents[${docIndex}].File`, document.file);
                            }
                            if (document.note !== undefined) {
                                formData.append(`MedicalEventsToAdd[${index}].Documents[${docIndex}].Note`, document.note);
                            }
                            if (document.summary !== undefined) {
                                formData.append(`MedicalEventsToAdd[${index}].Documents[${docIndex}].Summary`, document.summary);
                            }
                        });
                    });

                    medicalEventsToEdit?.forEach((event, index) => {
                        if (event.eventId !== undefined) {
                            formData.append(`MedicalEventsToEdit[${index}].EventId`, event.eventId);
                        }
                        if (event.eventTypeId !== undefined) {
                            formData.append(`MedicalEventsToEdit[${index}].EventTypeId`, event.eventTypeId);
                        }
                        if (event.followUpDate) {
                            formData.append(`MedicalEventsToEdit[${index}].FollowUpDate`, new Date(event.followUpDate).toISOString());
                        }
                        if (event.eventDate) {
                            formData.append(`MedicalEventsToEdit[${index}].EventDate`, new Date(event.eventDate).toISOString());
                        }
                        if (event.id !== undefined) {
                            formData.append(`MedicalEventsToEdit[${index}].Id`, event.id);
                        }

                        event.documents?.forEach((document, docIndex) => {
                            if (document.file !== undefined) {
                                formData.append(`MedicalEventsToEdit[${index}].Documents[${docIndex}].File`, document.file);
                            }
                            if (document.fileId) {
                                formData.append(`MedicalEventsToEdit[${index}].Documents[${docIndex}].FileId`, document.fileId);
                            }
                            if (document.note !== undefined) {
                                formData.append(`MedicalEventsToEdit[${index}].Documents[${docIndex}].Note`, document.note);
                            }
                            if (document.summary !== undefined) {
                                formData.append(`MedicalEventsToEdit[${index}].Documents[${docIndex}].Summary`, document.summary);
                            }
                            if (document.id !== undefined) {
                                formData.append(`MedicalEventsToEdit[${index}].Documents[${docIndex}].Id`, document.id);
                            }
                        });
                    });

                    return ({
                        method: 'POST',
                        url: `/student/${studentId}/medical`,
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                },
                invalidatesTags: ['MedicalInfo'],
            }),
            getSenNeeds: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/sen`
                }),
                providesTags: (_) => ['SenNeeds']
            }),
            manageSenNeeds: builder.mutation({
                query: ({
                            studentId,
                            senNeeds,
                            senStatus,
                            senNeedInvestigating,
                            addedSenDocuments,
                            deletedSenDocuments,
                            ehcpFile
                        }) => {
                    const formData = new FormData();

                    deletedSenDocuments.forEach((docId, index) => {
                        formData.append(`deletedSenDocuments[${index}]`, docId);
                    });

                    addedSenDocuments.forEach((doc, index) => {
                        formData.append(`addedSenDocuments[${index}].summary`, doc.summary);
                        formData.append(`addedSenDocuments[${index}].file`, doc.file);
                        formData.append(`addedSenDocuments[${index}].fileName`, doc.file.name);
                    });

                    if (ehcpFile) {
                        formData.append('addedEhcpDocument.file', ehcpFile);
                        formData.append('addedEhcpDocument.fileName', ehcpFile.name);
                    }

                    senNeeds.forEach((senNeed, index) => {
                        formData.append(`senNeeds[${index}].isActive`, senNeed.isActive);
                        formData.append(`senNeeds[${index}].name`, senNeed.name);
                        if (senNeed.note) {
                            formData.append(`senNeeds[${index}].note`, senNeed.note);
                        }
                        formData.append(`senNeeds[${index}].senNeedId`, senNeed.senNeedId);

                        if (senNeed.id) {
                            formData.append(`senNeeds[${index}].id`, senNeed.id);
                        }
                    });

                    if (senNeedInvestigating.id) {
                        formData.append('senNeedInvestigating.id', senNeedInvestigating.id);
                    }
                    formData.append('senNeedInvestigating.isChecked', senNeedInvestigating.isChecked);

                    if (senNeedInvestigating.note !== null) {
                        formData.append('senNeedInvestigating.note', senNeedInvestigating.note);
                    }

                    formData.append('senStatus', senStatus);
                    formData.append('studentId', studentId);

                    return {
                        url: `/student/${studentId}/sen`,
                        method: 'POST',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    };
                },
                invalidatesTags: ['SenNeeds']
            }),
            getTravelMode: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/travel`
                }),
                providesTags: (_) => [{type: 'Travel', id: 'VALUE'}]
            }),
            updateTravelMode: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/travel`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'Travel', id: 'VALUE'}]
            }),
            getConsents: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/consent`
                }),
                providesTags: (_) => [{type: 'Consents', id: 'LIST'}]
            }),
            updateConsents: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/consent`,
                    method: 'PATCH',
                    data: body,
                }),
                invalidatesTags: [{type: 'Consents', id: 'LIST'}]
            }),
            getStudentFilters: builder.query({
                query: (body) => ({
                    url: '/student/all/list',
                    method: 'POST',
                    data: body
                }),
                providesTags: (_) => ['Students']
            }),
            getStudentGroups: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/groups`
                }),
                transformResponse: (response) => response?.map(name => ({name})),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve school groups'})
            }),
        }),
});

export const {
    useGetDietaryNeedsQuery,
    useGetWelfareQuery,
    useManageDietaryNeedsMutation,
    useManageWelfareMutation,
    useGetFreeSchoolMealsQuery,
    useManageFreeSchoolMealsMutation,
    useGetMedicalInfoQuery,
    useManageMedicalSectionMutation,
    useGetSenNeedsQuery,
    useManageSenNeedsMutation,
    useGetTravelModeQuery,
    useUpdateTravelModeMutation,
    useGetConsentsQuery,
    useUpdateConsentsMutation,
    useGetStudentFiltersQuery,
    useGetStudentGroupsQuery
} = studentService;
