import React from 'react';
import {Box, Container, Divider, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import logo from '../../img/logo-new.png';

export default function SubmitPublicFormResult() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get('error');
    const studentName = queryParams.get('studentName');
    const schoolName = queryParams.get('schoolName');

    return (
        <Container maxWidth="sm">
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                {error
                    ? <Box textAlign="center">
                        <ErrorIcon color="error" sx={{
                            fontSize: 80,
                            mb: 2,
                            stroke: 'rgb(250, 250, 251)',
                            strokeWidth: 0.5,
                            opacity: 0.7
                        }}/>
                        <Divider color={'#D32F36'} sx={{mb: 2, width: '70%', mx: 'auto'}}/>
                        <Typography color="error" variant="h6" sx={{mb: 1}}>{error}</Typography>
                        <Typography>Please try again, and if the issue does not resolve, contact support at
                            support@admicity.co.uk for assistance.</Typography>
                        <img src={logo} alt="Admicity logo"
                             style={{height: 100, paddingTop: '10px', paddingBottom: '10px'}}/>
                        <Typography variant={'subtitle2'}>
                            © Admicity Ltd {new Date().getFullYear()} - Company Registration No.15190132
                        </Typography>
                    </Box>
                    : <Box textAlign="center">
                        <CheckCircleIcon color="success" sx={{
                            fontSize: 80,
                            mb: 2,
                            stroke: 'rgb(250, 250, 251)',
                            strokeWidth: 1,
                            opacity: 0.7
                        }}/>
                        <Divider color={'#0D953B'} sx={{mb: 2, width: '70%', mx: 'auto'}}/>
                        <Typography color={'#0D953B'}>
                            Your application form for {studentName} was has been
                            successfully and securely submitted to {schoolName}.
                        </Typography>
                        <img src={logo} alt="Admicity logo"
                             style={{height: 100, paddingTop: '10px', paddingBottom: '10px'}}/>
                        <Typography color={'#0D953B'} variant={'subtitle2'}>
                            © Admicity Ltd {new Date().getFullYear()} - Company Registration No.15190132
                        </Typography>
                    </Box>
                }
            </Box>
        </Container>
    );
}
