import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    styled,
    TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {FileDownload, UploadFile} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {useGetMedicalConsentOptionsQuery} from '../../../../../api/services/lookupsService';
import {useGetMedicalInfoQuery, useManageMedicalSectionMutation,} from '../../../../../api/services/studentService';
import {
    downloadProfileMedicalConditionDocument,
    downloadProfileVaccinationDocument
} from '../../../../../api/services/filesService';
import useFileDownloader from '../../../../../utility/hooks/useFileDownloader';
import {useDispatch, useSelector} from 'react-redux';
import {Item} from '../DietaryWelfareEthnicityTab/FreeSchoolMealsFields';
import {retrieveProfile} from '../../ProfilesSlice';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import Uploader from '../../../../SchoolManagement/Setup/Uploader';
import AdmicityDialog from '../../../../../shared-components/AdmicityDialog';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {generateUUID} from '../../../../../utility/uuidUtil';
import MedicalEvents from './MedicalEvents';
import MedicalNotes from './MedicalNotes';
import {MEDICAL_CONDITION_STATUSES} from '../../const/MedicalConditionStatus';
import PermissionCheck from '../../Components/PermissionCheck';

const MedicalFields = styled(Container)({
    display: 'flex', flexDirection: 'column', justifyContent: 'start'
});

export const MedicalRow = styled(Box)({
    display: 'flex', justifyContent: 'space-between', alignItems: 'center'
});

const DownloadIcon = styled(FileDownload)(({theme}) => ({
    color: theme.palette.primary.main, cursor: 'pointer'
}));

const MedicalInfoTab = () => {
    const [rows, setRows] = useState([]);
    const [eventRows, setEventRows] = useState([]);
    const [noteRows, setNoteRows] = useState([]);
    const {studentId} = useParams();
    const [isVaccinationDeleteClicked, setIsVaccinationDeleteClicked] = useState(false);
    const [vaccinationFile, setVaccinationFile] = useState(null);
    const [vaccinationFileToDelete, setVaccinationFileToDelete] = useState(null);
    const [gpDetails, setGpDetails] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [deselectedOptions, setDeselectedOptions] = useState([]);
    const [isFileSizeError, setIsFileSizeError] = useState(false);
    const [medicalConditionStatus, setMedicalConditionStatus] = useState(undefined);
    const {selectedProfile, currentSchool} = useSelector(state => state.profilesInfo);
    const dispatch = useDispatch();
    const [openAddConditionDocumentsDialog, setOpenAddConditionDocumentsDialog] = useState(false);
    const [openAddEventDocumentsDialog, setOpenAddEventDocumentsDialog] = useState(false);
    const [openAddNoteDocumentsDialog, setOpenAddNoteDocumentsDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const [targetConditionId, setTargetConditionId] = useState(undefined);
    const [totalUploadFilesSize, setTotalUploadFilesSize] = useState(25);
    const [isConditionsReadonly, setIsConditionsReadonly] = useState(false);
    const [isEventsReadonly, setIsEventsReadonly] = useState(false);

    const [manageMedicalSection] = useManageMedicalSectionMutation();

    const {
        data: medicalInfo = {options: []},
        isLoading: isMedicalInfoLoading,
        refetch: refetchMedicalInfoData
    } = useGetMedicalInfoQuery(studentId);

    const {
        data: medicalConsentOptions = {options: []},
        isLoading: isMedicalConsentOptionsLoading,
        refetch: refetchMedicalConsentsData
    } = useGetMedicalConsentOptionsQuery();

    const refetchData = async () => {
        await refetchMedicalInfoData();
        await refetchMedicalConsentsData();
    };
    const downloadFile = useFileDownloader();

    useEffect(() => {
        if (!isMedicalInfoLoading) {
            setMedicalConditionStatus(medicalInfo.medicalConditionStatus);
            setRows(medicalInfo.medicalConditions);
            setEventRows(medicalInfo.medicalEvents);
            setNoteRows(medicalInfo.medicalNotes);
            setGpDetails(medicalInfo.gpInfo);
            setSelectedOptions(medicalInfo.medicalConsentOptions?.options.map(x => x.optionId));
        }
    }, [isMedicalInfoLoading, medicalInfo]);

    const getHisHerPronoun = () => {
        if (!selectedProfile.gender) {
            return 'his/her';
        }
        return selectedProfile.gender === 'M' ? 'his' : 'her';
    }

    const handleDialogClose = () => {
        setOpenAddConditionDocumentsDialog(false);
        setOpenAddNoteDocumentsDialog(false);
        setOpenAddEventDocumentsDialog(false);
        setSelectedFiles([]);
        setIsUploading(false);
        setIsUploadSizeExceedsLimit(false);
        setTargetConditionId(undefined);
    };

    const updateMedicalEvents = (updatedValue, isDelete = false) => {
        if (isDelete && updatedValue.isNew) {
            setEventRows(eventRows.filter(e => updatedValue.id ? e.id !== updatedValue.id : e.uuid !== updatedValue.uuid));
            return;
        }

        if (!updatedValue.id && !updatedValue.uuid) {
            updatedValue.uuid = generateUUID();
            updatedValue.isNew = true;
            setEventRows([updatedValue, ...eventRows]);
            return;
        }

        const updatedRows = eventRows.map(row => {
            if (updatedValue.id ? row.id === updatedValue.id : row.uuid === updatedValue.uuid) {
                return {
                    ...row,
                    ...updatedValue,
                    isEdited: !updatedValue.isNew,
                    isDeleted: isDelete,
                    documents: updatedValue.documents
                };
            }
            return row;
        });

        setEventRows(updatedRows);
        setSelectedFiles([])
    }

    const updateMedicalNotes = (updatedValue, isDelete = false) => {
        if (isDelete && updatedValue.isNew) {
            setNoteRows(noteRows.filter(e => updatedValue.id ? e.id !== updatedValue.id : e.uuid !== updatedValue.uuid));
            return;
        }

        if (!updatedValue.id && !updatedValue.uuid) {
            updatedValue.uuid = generateUUID();
            updatedValue.isNew = true;
            setNoteRows([updatedValue, ...noteRows]);
            return;
        }

        const updatedRows = noteRows.map(row => {
            if (updatedValue.id ? row.id === updatedValue.id : row.uuid === updatedValue.uuid) {
                return {
                    ...row,
                    ...updatedValue,
                    isEdited: !updatedValue.isNew,
                    isDeleted: isDelete,
                };
            }
            return row;
        });

        setNoteRows(updatedRows);
        setSelectedFiles([])
    }

    const handleConditionDocumentsUploaded = async () => {
        setIsUploading(true);
        const updatedRows = rows.map(row => ({
            ...row,
            medicalCondition: row.medicalCondition
                ? {
                    ...row.medicalCondition,
                    documents: row.medicalCondition.documents
                        ? row.medicalCondition.documents.map(document => ({
                            ...document
                        }))
                        : []
                }
                : null
        }));

        const targetCondition = updatedRows.find(row => row.id === targetConditionId);

        if (targetCondition) {
            targetCondition.isEdited = true;

            const newFiles = selectedFiles.map(file => ({
                file,
                summary: '',
                note: '',
                name: file.name,
                isActive: true
            }));

            targetCondition.medicalCondition.documents = [
                ...newFiles,
                ...(targetCondition.medicalCondition.documents || [])
            ];

            setTotalUploadFilesSize(totalUploadFilesSize - selectedFiles.reduce((total, file) => {
                return total + file.size;
            }, 0) / (1024 * 1024))
        }

        setRows(updatedRows);
        setTargetConditionId(undefined);
        handleDialogClose();
    };

    const handleSelectFiles = (files) => {
        const newDocuments = files.filter(file => !selectedFiles.some(s => s.name === file.name));
        setSelectedFiles([...selectedFiles, ...newDocuments]);
    };

    const handleSelectFile = (files) => {
        setSelectedFiles([...files]);
    };

    const handleFileDeleteFromUploadContext = (filename) => {
        const files = [...selectedFiles];
        setSelectedFiles(files.filter(f => f.name !== filename));
    };

    const updateMedicalCondition = async ({
                                              summary,
                                              note,
                                              isActive,
                                              conditionId,
                                              documentId,
                                              filename,
                                              isDeleteDocument
                                          }) => {
        const updatedRows = rows.map(row => ({
            ...row,
            medicalCondition: row.medicalCondition
                ? {
                    ...row.medicalCondition,
                    documents: row.medicalCondition.documents
                        ? row.medicalCondition.documents.map(document => ({
                            ...document
                        }))
                        : []
                }
                : null
        }));

        const targetCondition = updatedRows.find(row => row.id === conditionId);

        if (!targetCondition) return;

        targetCondition.isEdited = true;

        if (targetCondition.medicalCondition) {
            const conditionDocumentToUpdate = documentId
                ? targetCondition.medicalCondition.documents.find(file => file.id === documentId)
                : targetCondition.medicalCondition.documents.find(file => file.name === filename);

            if (conditionDocumentToUpdate) {
                if (note !== undefined) {
                    conditionDocumentToUpdate.note = note;
                }
                if (summary !== undefined) {
                    conditionDocumentToUpdate.summary = summary;
                }
                if (isDeleteDocument !== undefined) {
                    conditionDocumentToUpdate.isActive = false;

                    if (conditionDocumentToUpdate.file) {
                        setTotalUploadFilesSize(totalUploadFilesSize + (conditionDocumentToUpdate.file.size / 1024 / 1024));
                    }
                }
            }

            if (isActive !== undefined) {
                if (isActive) {
                    targetCondition.medicalCondition.isActive = isActive;
                } else {
                    const originalCondition = medicalInfo.medicalConditions.find(e => e.id === conditionId);

                    setTotalUploadFilesSize(totalUploadFilesSize + targetCondition.medicalCondition.documents.reduce((total, document) => {
                        if (!document.file) {
                            return total;
                        }

                        return total + document.file.size;
                    }, 0) / (1024 * 1024))

                    if (originalCondition?.medicalCondition) {
                        targetCondition.medicalCondition.isActive = isActive;
                    } else {
                        delete targetCondition.isEdited;
                        targetCondition.medicalCondition = null;
                    }
                }
            }
        } else {
            targetCondition.medicalCondition = {isActive: true};
        }
        setRows(updatedRows);
    };

    const handleVaccinationFileUploaded = (file) => {
        if (!file) return;

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 15) {
            setIsFileSizeError(true);
            setVaccinationFile({name: file.name});
        } else {
            setIsFileSizeError(false);
            setVaccinationFile(file);
        }

        if (medicalInfo.vaccination?.fileId) {
            setVaccinationFileToDelete(medicalInfo.vaccination?.fileId);
        }

        setIsVaccinationDeleteClicked(false);
    };

    const handleVaccinationFileDelete = async (fileId) => {
        setIsVaccinationDeleteClicked(true);
        if (vaccinationFile !== null) {
            setVaccinationFile(null);
        } else {
            setVaccinationFileToDelete(fileId);
        }
        setIsFileSizeError(false);
    };

    const handleCheckMedicalConsents = (event) => {
        const {checked, value} = event.target;
        const optionId = parseInt(value);

        if (checked) {
            setSelectedOptions([...selectedOptions, optionId]);
            setDeselectedOptions(deselectedOptions.filter(id => id !== optionId));
        } else {
            setDeselectedOptions([...deselectedOptions, optionId]);
            setSelectedOptions(selectedOptions.filter(id => id !== optionId));
        }
    };

    const isNothingChanged = () => {
        const isMedicalRowsInitial = rows.filter(row => row.isEdited).length === 0;
        const isNoteRowsInitial = noteRows.filter(row => row.isEdited || row.isDeleted || row.isNew).length === 0;
        const isEventRowsInitial = eventRows.filter(row => row.isEdited || row.isDeleted || row.isNew).length === 0;
        const isGpDetailsInitial = gpDetails === medicalInfo.gpInfo;
        const isMedicalStatusInitial = medicalConditionStatus === medicalInfo.medicalConditionStatus;
        const isSelectedOptionsClear = selectedOptions
            .filter(x => !medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)).length === 0;
        const isDeselectedOptionsClear = deselectedOptions
            .filter(x => medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)).length === 0;
        const isVaccinationFileInitial = vaccinationFile === null && vaccinationFileToDelete === null;
        return isMedicalRowsInitial && isMedicalStatusInitial && isGpDetailsInitial && isSelectedOptionsClear && isDeselectedOptionsClear && isVaccinationFileInitial && isNoteRowsInitial && isEventRowsInitial;
    };

    const handleSave = async () => {
        const updatedRows = rows
            .filter(row => row.isEdited)
            .map(row => {
                return {
                    name: row.name,
                    medicalInfoId: row.id,
                    medicalConditionId: row.medicalCondition.id,
                    ...row.medicalCondition
                };
            });

        const payload = {
            studentId,
            medicalConditions: updatedRows,
            medicalNotesToDelete: noteRows.filter(e => e.isDeleted).map(e => e.id),
            medicalNotesToAdd: noteRows.filter(e => e.isNew).map(e => ({
                file: e.file,
                note: e.note,
                summary: e.summary
            })),
            medicalNotesToEdit: noteRows.filter(e => e.isEdited && !e.isDeleted).map(e => ({
                id: e.id,
                file: e.file,
                fileId: e.fileId,
                note: e.note,
                summary: e.summary
            })),
            medicalEventsToDelete: eventRows.filter(e => e.isDeleted).map(e => e.id),
            medicalEventsToAdd: eventRows.filter(e => e.isNew).map(e => ({
                eventId: e.eventId,
                eventTypeId: e.eventTypeId,
                followUpDate: e.followUpDate,
                eventDate: e.eventDate,
                documents: e.documents?.map(d => ({
                    file: d.file,
                    note: d.note,
                    summary: d.summary
                }))
            })),
            medicalEventsToEdit: eventRows.filter(e => e.isEdited && !e.isDeleted).map(e => ({
                eventId: e.eventId,
                eventTypeId: e.eventTypeId,
                followUpDate: e.followUpDate,
                eventDate: e.eventDate,
                id: e.id,
                documents: e.documents.map(d => ({
                    file: d.file,
                    fileId: d.fileId,
                    note: d.note,
                    summary: d.summary,
                    id: d.id
                }))
            })),
            vaccination: vaccinationFile,
            medicalConditionStatus,
            gpDetails,
            deletedVaccinationFileId: vaccinationFileToDelete,
            selectedConsents: selectedOptions
                .filter(x => !medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)),
            deselectedConsents: deselectedOptions
                .filter(x => medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x))
        };
        await manageMedicalSection(payload).then(() => dispatch(retrieveProfile({studentId: selectedProfile.id})));
        setVaccinationFile(null);
        setVaccinationFileToDelete(null);
        setTotalUploadFilesSize(25);
    };

    return (
        isMedicalInfoLoading || isMedicalConsentOptionsLoading
            ? ''
            : <MedicalFields item xs={12} sm={6} px={3}>
                <ConfirmationWrapper
                    readonly={false}
                    confirmData={{
                        confirmMessage: `I confirm that the information in the medical profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                        confirmedAt: medicalInfo.confirmedAt,
                        confirmedBy: medicalInfo.confirmedBy,
                        enableSaveButton: !isNothingChanged() && (isConditionsReadonly || (medicalConditionStatus && (!(rows.some(row => row.medicalCondition &&
                                    row.medicalCondition.documents && row.medicalCondition.documents.some(document => !document.summary))) &&
                                (!(rows.every(row => !row.medicalCondition || !row.medicalCondition.isActive) && medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION))))) &&
                            (isEventsReadonly || eventRows.every(e => !e.documents || e.documents?.every(d => d.summary))),
                        enableConfirmButton: isNothingChanged() && (isConditionsReadonly || (medicalConditionStatus && !(rows.every(row => !row.medicalCondition || !row.medicalCondition.isActive) && medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION))),
                        studentId,
                        type: STUDENT_PROFILE_CONFIRMATION_TYPES.Medical,
                        handleSave,
                        onConfirmSuccess: async () => await refetchData(),
                        confirmButtonText: 'Confirm Medical Information'
                    }}
                >
                    <Typography variant="h5" py={2}>MEDICAL INFORMATION
                        FOR {selectedProfile.name?.toUpperCase()}</Typography>
                    <Typography fontWeight={'bold'}>Please take care to complete this section of {selectedProfile.name}’s
                        Student Profile accurately. It is
                        your responsibility to ensure that it is correct and up-to-date at all times. The information
                        you provide will be relied upon by school staff and any other person or organisation who may
                        have {selectedProfile.name} in their care on behalf of {currentSchool.name}.</Typography>
                    <Divider sx={{mt: 2, mb: 2}}/>
                    <PermissionCheck
                        permissionModule="StudentMedicalConditions"
                        returnType={'isEdit'}
                        render={(isEdit) => {
                            setIsConditionsReadonly(!isEdit);
                            return (isEdit || medicalConditionStatus) && <>
                                <Typography variant="h5" pb={2}>Medical Conditions</Typography>
                                {!medicalConditionStatus &&
                                    <Typography>Please tick one of the following boxes:</Typography>}
                                {
                                    medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION && medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED &&
                                    <Item>
                                        <Typography>{selectedProfile.name} does not have any medical conditions
                                            that {currentSchool.name} need to be aware of.</Typography>
                                        <Checkbox
                                            sx={{width: '25px', height: '25px'}}
                                            value={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION}
                                            checked={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION}
                                            disabled={!isEdit || rows.some(row => row.medicalCondition && row.medicalCondition.isActive)}
                                            onChange={event => setMedicalConditionStatus(event.target.checked ? MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION : undefined)}
                                        />
                                    </Item>
                                }
                                {
                                    medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED && medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION &&
                                    <Item>
                                        <Typography>{selectedProfile.name} has one or more medical conditions that have been
                                            formally
                                            diagnosed by an appropriately qualified professional.</Typography>
                                        <Checkbox
                                            sx={{width: '25px', height: '25px'}}
                                            value={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION}
                                            checked={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION}
                                            disabled={!isEdit || rows.some(row => row.medicalCondition && row.medicalCondition.isActive)}
                                            onChange={event => setMedicalConditionStatus(event.target.checked ? MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION : undefined)}
                                        />
                                    </Item>
                                }
                                {medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION && <>
                                    <Typography pb={2}>
                                        Please tick {selectedProfile.name}’s medical condition(s).
                                        If {selectedProfile.name} has a medical
                                        condition does not appear in the list please select Other/Any Other Medical
                                        Condition. You
                                        should use the &apos;upload&apos; button to provide supplementary documentation -
                                        for example a
                                        diagnosis letter or a management plan related to the condition. The Summary field
                                        that
                                        appears beneath the uploaded document should be used to describe the type of
                                        document being
                                        uploaded. The Notes field below the Summary can be used to provide additional
                                        details and
                                        information you would like {medicalInfo.schoolName} to know
                                        about {selectedProfile.name}’s medical condition,
                                        for example, the type and dose of medication required and whether it is taken during
                                        the
                                        school day.</Typography>
                                    {rows?.map((condition) => {
                                            return (
                                                <Box key={condition.name}>
                                                    <MedicalRow>
                                                        <Typography>{condition.name}</Typography>
                                                        <Checkbox
                                                            sx={{width: '25px', height: '25px'}}
                                                            checked={!!condition.medicalCondition && condition.medicalCondition.isActive}
                                                            onChange={event => updateMedicalCondition({
                                                                isActive: event.target.checked,
                                                                conditionId: condition.id
                                                            })}
                                                            disabled={!isEdit}
                                                        />
                                                    </MedicalRow>
                                                    {condition.medicalCondition && condition.medicalCondition.isActive
                                                        ? <>

                                                            <Box py={1} display="flex" alignItems="center">
                                                                {
                                                                    !isEdit
                                                                        ? ''
                                                                        : <Button
                                                                            variant="contained"
                                                                            startIcon={<UploadFile/>}
                                                                            onClick={() => {
                                                                                setTargetConditionId(condition.id);
                                                                                setOpenAddConditionDocumentsDialog(true);
                                                                            }}
                                                                        >
                                                                            Upload Documents
                                                                        </Button>
                                                                }
                                                            </Box>
                                                            {
                                                                condition?.medicalCondition?.documents
                                                                    ?.filter(e => e.isActive)
                                                                    .map(file => {
                                                                            return <>
                                                                                <Box key={file.id} display={'flex'}
                                                                                     alignItems={'center'} py={1}>
                                                                                    <Accordion sx={{width: '100%'}}
                                                                                               defaultExpanded={!file.fileId}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ArrowDropDownIcon/>}>
                                                                                            <Box display="flex"
                                                                                                 justifyContent="space-between"
                                                                                                 alignItems="center"
                                                                                                 sx={{
                                                                                                     maxWidth: {
                                                                                                         xs: '250px',
                                                                                                         sm: '395px',
                                                                                                         md: '695px',
                                                                                                         lg: '995px',
                                                                                                         xl: '1050px',
                                                                                                     }
                                                                                                 }}>
                                                                                                < Typography
                                                                                                    sx={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        whiteSpace: 'nowrap',
                                                                                                        flexGrow: 1,
                                                                                                        marginRight: '8px',
                                                                                                    }}
                                                                                                >
                                                                                                    {file?.name}
                                                                                                </Typography>
                                                                                                {file.fileId && (
                                                                                                    <IconButton
                                                                                                        sx={{
                                                                                                            flexShrink: 0,
                                                                                                        }}
                                                                                                        onClick={async (e) => {
                                                                                                            e.stopPropagation()
                                                                                                            await downloadFile(downloadProfileMedicalConditionDocument, {
                                                                                                                documentId: file.fileId,
                                                                                                                studentId
                                                                                                            })
                                                                                                        }}
                                                                                                    >
                                                                                                        <DownloadIcon/>
                                                                                                    </IconButton>
                                                                                                )}
                                                                                            </Box>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <TextField
                                                                                                fullWidth
                                                                                                label={'Summary'}
                                                                                                required
                                                                                                value={file.summary}
                                                                                                onChange={event => updateMedicalCondition({
                                                                                                    summary: event.target.value,
                                                                                                    conditionId: condition.id,
                                                                                                    documentId: file.id,
                                                                                                    filename: file?.name
                                                                                                })}
                                                                                                disabled={!isEdit}
                                                                                            />
                                                                                            <TextField
                                                                                                sx={{my: 2}}
                                                                                                multiline
                                                                                                fullWidth
                                                                                                label={'Note'}
                                                                                                value={file.note}
                                                                                                onChange={event => updateMedicalCondition({
                                                                                                    note: event.target.value,
                                                                                                    conditionId: condition.id,
                                                                                                    documentId: file.id,
                                                                                                    filename: file?.name
                                                                                                })}
                                                                                                disabled={!isEdit}
                                                                                            />
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                    {
                                                                                        isEdit && <IconButton
                                                                                            onClick={() => updateMedicalCondition({
                                                                                                conditionId: condition.id,
                                                                                                documentId: file.id,
                                                                                                filename: file?.name,
                                                                                                isDeleteDocument: true
                                                                                            })}>
                                                                                            <DeleteIcon/>
                                                                                        </IconButton>
                                                                                    }
                                                                                </Box>

                                                                            </>
                                                                        }
                                                                    )
                                                            }
                                                        </>
                                                        : <></>
                                                    }
                                                </Box>
                                            );
                                        }
                                    )}
                                </>
                                }
                                {
                                    medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION && medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION &&
                                    <Item>
                                        <Typography>{selectedProfile.name} has a suspected medical condition that has not
                                            yet been
                                            formally diagnosed.</Typography>
                                        <Checkbox
                                            sx={{width: '25px', height: '25px'}}
                                            value={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED}
                                            checked={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED}
                                            disabled={!isEdit || rows.some(row => row.medicalCondition && row.medicalCondition.isActive)}
                                            onChange={event => setMedicalConditionStatus(event.target.checked ? MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED : undefined)}
                                        />
                                    </Item>
                                }
                                {
                                    medicalConditionStatus === MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED &&
                                    <Typography variant={'body2'}>Please use the Medical Notes section of this page to
                                        provide details and upload any
                                        supplementary documentation. If Medical Notes is not visible on this page, please
                                        use the Documentation tab of {selectedProfile.name}&apos;s student profile to upload information
                                        about {getHisHerPronoun()} medical condition.</Typography>
                                }
                                <Divider sx={{mt: 2, mb: 2}}/>
                            </>
                        }
                        }
                    />
                    <PermissionCheck
                        permissionModule="StudentMedicalEvents"
                        returnType={'isEdit'}
                        render={(isEdit) => {
                            setIsEventsReadonly(!isEdit)
                            return (isEdit || eventRows?.length > 0) && <>
                                <MedicalEvents
                                    readonly={!isEdit}
                                    medicalEvents={eventRows}
                                    downloadFile={downloadFile}
                                    studentId={studentId}
                                    isUploading={isUploading}
                                    selectedFiles={selectedFiles}
                                    isUploadSizeExceedsLimit={isUploadSizeExceedsLimit}
                                    totalUploadFilesSize={totalUploadFilesSize}
                                    handleSelectFiles={handleSelectFiles}
                                    handleFileDeleteFromUploadContext={handleFileDeleteFromUploadContext}
                                    setIsUploadSizeExceedsLimit={setIsUploadSizeExceedsLimit}
                                    handleDialogClose={handleDialogClose}
                                    updateMedicalEvents={updateMedicalEvents}
                                    setTotalUploadFilesSize={setTotalUploadFilesSize}
                                    setIsUploading={setIsUploading}
                                />
                                <Divider sx={{mt: 2, mb: 2}}/>
                            </>
                        }
                        }
                    />
                    <PermissionCheck
                        permissionModule="StudentMedicalNotes"
                        returnType={'isEdit'}
                        render={(isEdit) => {
                            return (isEdit || noteRows.length > 0) && <>
                                <MedicalNotes
                                    readonly={!isEdit}
                                    medicalNotes={noteRows}
                                    downloadFile={downloadFile}
                                    studentId={studentId}
                                    isUploading={isUploading}
                                    selectedFiles={selectedFiles}
                                    isUploadSizeExceedsLimit={isUploadSizeExceedsLimit}
                                    totalUploadFilesSize={totalUploadFilesSize}
                                    handleSelectFiles={handleSelectFile}
                                    handleFileDeleteFromUploadContext={handleFileDeleteFromUploadContext}
                                    setIsUploadSizeExceedsLimit={setIsUploadSizeExceedsLimit}
                                    handleDialogClose={handleDialogClose}
                                    updateMedicalNotes={updateMedicalNotes}
                                    setTotalUploadFilesSize={setTotalUploadFilesSize}
                                    setIsUploading={setIsUploading}
                                />
                                <Divider sx={{mt: 2, mb: 2}}/>
                            </>
                        }
                        }
                    />
                    <Typography variant='h5' pb={2}>Non-Prescription (OTC) Medication</Typography>
                    <Typography
                        variant="body1">Do you give consent for the {medicalInfo.schoolName} to administer the
                        following medication to {selectedProfile.name} during the school day if required?
                    </Typography>
                    {medicalConsentOptions.options?.map((field, index) => (
                        <Box
                            key={index}
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography>{field.description}</Typography>
                            <Checkbox
                                sx={{width: '25px', height: '25px'}}
                                value={field.id}
                                checked={selectedOptions?.some(id => id === field.id)}
                                onChange={handleCheckMedicalConsents}
                            />
                        </Box>
                    ))}
                    <Divider sx={{mt: 2, mb: 2}}/>
                    <Box>
                        <Typography variant="h5" py={2}>GP Details</Typography>
                        <Typography variant="body1">Please provide the name and address
                            of {`${selectedProfile.name}’s`} GP</Typography>
                        <TextField
                            multiline
                            fullWidth
                            value={gpDetails}
                            onChange={event => event.target.value.length < 255 && setGpDetails(event.target.value)}
                        />
                    </Box>
                    <Typography variant="body1" sx={{paddingTop: 3}}>Please upload {`${selectedProfile.name}’s`} vaccination
                        record. This can
                        be obtained from your GP surgery.</Typography>
                    <Box py={1} display={'flex'} justifyContent={'space-between'}>
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            <Button
                                component="label"
                                variant="outlined"
                                startIcon={<UploadFile/>}
                                sx={{marginRight: 1}}
                            >
                                Upload
                                <input type="file" hidden
                                       onChange={event => handleVaccinationFileUploaded(event.target.files[0])}/>
                            </Button>
                            {!isVaccinationDeleteClicked &&
                                <>
                                    <Typography>
                                        {vaccinationFile ? vaccinationFile.name : medicalInfo.vaccination?.fileName}
                                    </Typography>
                                    {medicalInfo.vaccination?.fileId && vaccinationFile === null &&
                                        <IconButton onClick={async () => await downloadFile(
                                            downloadProfileVaccinationDocument,
                                            {
                                                documentId: medicalInfo.vaccination?.fileId,
                                                studentId
                                            })
                                        }>
                                            <DownloadIcon></DownloadIcon>
                                        </IconButton>
                                    }
                                </>
                            }
                        </Box>
                        <Button variant="outlined"
                                onClick={() => handleVaccinationFileDelete(medicalInfo.vaccination?.fileId)}
                                sx={{
                                    width: '100px',
                                    visibility: (!medicalInfo.vaccination?.fileId && vaccinationFile === null) || isVaccinationDeleteClicked ? 'hidden' : 'visible'
                                }}
                        >
                            Delete
                        </Button>
                    </Box>
                    {
                        isFileSizeError && <Alert sx={{marginTop: 2}} severity="error">
                            The total size of the file exceeds 15 MB.
                        </Alert>
                    }

                </ConfirmationWrapper>
                <AdmicityDialog
                    handleClose={handleDialogClose}
                    title={'Add Documents'}
                    open={openAddNoteDocumentsDialog || openAddConditionDocumentsDialog || openAddEventDocumentsDialog}
                    actions={[
                        {label: 'Cancel', onClick: handleDialogClose},
                        {
                            label: 'Upload',
                            onClick: handleConditionDocumentsUploaded,
                            disabled: isUploading || selectedFiles?.length === 0 || isUploadSizeExceedsLimit,
                            startIcon: isUploading
                                ? <CircularProgress color="inherit" size={16}/>
                                : undefined
                        }
                    ]}
                    maxWidth={'md'}
                >
                    <Uploader
                        totalUploadSizeInMb={totalUploadFilesSize}
                        onFilesSelect={handleSelectFiles}
                        onFileDelete={handleFileDeleteFromUploadContext}
                        onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                        value={selectedFiles}
                        readonly={isUploading}
                    />
                </AdmicityDialog>

            </MedicalFields>
    );
};

export default MedicalInfoTab;
