import {Navigate, useParams} from 'react-router-dom';
import {ROLE_BASE_ROUTE, ROLES} from '../constants/roles';
import {getRole} from '../utility/jwtUtil';
import SchoolDashboardPage from '../pages/SchoolDashboard/SchoolDashboardPage';
import ProtectedRoute from '../shared-components/ProtectedRoute';
import SchoolSettings from '../pages/SchoolDashboard/SchoolSettings/SchoolSettings';
import ProfilesTab from '../pages/SchoolDashboard/Profiles/ProfilesTab';
import ProfilesOverview from '../pages/SchoolDashboard/Profiles/ProfilesOverview';
import ProfileDetails from '../pages/SchoolDashboard/Profiles/ProfileDetails';
import BasicInfoTab from '../pages/SchoolDashboard/Profiles/ProfileTabs/BasicInfoTab/BasicInfoTab';
import MedicalInfoTab from '../pages/SchoolDashboard/Profiles/ProfileTabs/MedicalTab/MedicalInfoTab';
import SenAndDisabilitiesTab from '../pages/SchoolDashboard/Profiles/ProfileTabs/SenAndDisabilitiesTab';
import ProfileDocumentsTab from '../pages/SchoolDashboard/Profiles/ProfileTabs/ProfileDocumentsTab';
import ConsentsTab from '../pages/SchoolDashboard/Profiles/ProfileTabs/ConsentsTab/ConsentsTab';
import DietaryWelfareEthnicityTab
    from '../pages/SchoolDashboard/Profiles/ProfileTabs/DietaryWelfareEthnicityTab/DietaryWelfareEthnicityTab';
import FormsTab from '../pages/SchoolDashboard/Forms/FormsTab';
import Forms from '../pages/SchoolDashboard/Forms/Forms';
import RequestAndResponsesTab from '../pages/SchoolDashboard/RequestAndResponses/RequestAndResponsesTab';
import RequestAndResponses from '../pages/SchoolDashboard/RequestAndResponses/components/RequestAndResponses';
import SubmitPrivateForm from '../pages/SchoolDashboard/RequestAndResponses/components/SubmitPrivateForm';
import DocumentsTab from '../pages/SchoolDashboard/Documents/DocumentsTab';
import SchoolManagementPage from '../pages/SchoolManagement/SchoolManagementPage';
import SchoolManagementDashboard from '../pages/SchoolManagement/Dashboard/SchoolManagementDashboard';
import SchoolSetup from '../pages/SchoolManagement/Setup/SchoolSetup';
import NotFoundPage from '../pages/NotFound/NotFoundPage';
import LoginPage from '../pages/Login/LoginPage';
import ForgotPasswordPage from '../pages/ForgotPassword/ForgotPasswordPage';
import SubmitPublicForm from '../pages/SchoolDashboard/RequestAndResponses/components/SubmitPublicForm';
import RegistrationPage from '../pages/Registration/RegistrationPage';
import ResetPasswordPage from '../pages/ResetPassword/ResetPasswordPage';
import RedirectRoute from './RedirectRoute';
import FormEditor from '../pages/SchoolDashboard/Forms/FormEditor';
import PermissionCheck from '../pages/SchoolDashboard/Profiles/Components/PermissionCheck';
import ContactsAndEthnicityTab
    from '../pages/SchoolDashboard/Profiles/ProfileTabs/ContactsAndEthniicityTab/ContactsAndEthnicityTab';
import FormResponses from '../pages/SchoolDashboard/Forms/FormResponses/FormResponses';
import SubmitPublicFormResult from '../pages/PublicJotForm/SubmitPublicFormResult';
import PublicJotForm from '../pages/PublicJotForm/PublicJotForm';
import FormResponsesPage from '../pages/SchoolDashboard/Forms/FormResponses/FormResponsesPage';
import StudentForms from '../pages/SchoolDashboard/Profiles/ProfileTabs/FormsTab/StudentForms';
import StudentFormsTab from '../pages/SchoolDashboard/Profiles/ProfileTabs/FormsTab/StudentFormsTab';
import DownloadPage from '../pages/Download/DownloadPage';
import {FORM_ACCESS_LEVELS} from '../constants/formAccessLevels';
import InviteRecoveryPage from '../pages/InviteRecovery/InviteRecoveryPage';
import StudentGroupsTab from '../pages/SchoolDashboard/Profiles/ProfileTabs/GroupsTab/StudentGroupsTab';

const NavigateFromPrivateFormToResponsesPageWrapper = () => {
    const {id} = useParams();
    const navigateBackRoute = `/forms/responses/${id}`;

    return <SubmitPrivateForm navigateBackRoute={navigateBackRoute}/>;
};

const routes = [
    {
        path: '/',
        element: <Navigate to={ROLE_BASE_ROUTE[getRole()]} replace/>
    },
    {
        path: '/',
        element: <SchoolDashboardPage/>,
        children: [
            {
                path: 'school',
                element: <ProtectedRoute allowedRoles={[ROLES.SCHOOL_ADMIN]}>
                    <SchoolSettings/>
                </ProtectedRoute>,
            },
            {
                path: 'download/:id',
                element: <ProtectedRoute allowedRoles={[
                    ROLES.MEMBER_OF_STAFF,
                    ROLES.SCHOOL_ADMIN,
                    ROLES.PARENT
                ]}>
                    <DownloadPage/>
                </ProtectedRoute>,
            },
            {
                path: 'profiles',
                element: <ProtectedRoute
                    allowedRoles={[
                        ROLES.MEMBER_OF_STAFF,
                        ROLES.SCHOOL_ADMIN,
                        ROLES.PARENT
                    ]}
                >
                    <ProfilesTab/>
                </ProtectedRoute>,
                children: [
                    {
                        path: '',
                        element: <ProfilesOverview/>,
                    },
                    {
                        path: ':studentId',
                        element: <ProfileDetails/>,
                        children: [
                            {
                                path: 'basic',
                                element: <PermissionCheck
                                    permissionModule={'StudentProfile'}
                                    render={(readonly) => <BasicInfoTab readonly={readonly}/>}
                                />
                            },
                            {path: 'contact', element: <ContactsAndEthnicityTab/>},
                            {path: 'medical', element: <MedicalInfoTab/>},
                            {
                                path: 'sen',
                                element: <PermissionCheck
                                    permissionModule={'StudentSEN'}
                                    render={(readonly) => <SenAndDisabilitiesTab readonly={readonly}/>}
                                />
                            },
                            {path: 'documentation', element: <ProfileDocumentsTab/>},
                            {path: 'consents', element: <ConsentsTab/>},
                            {path: 'ethnicity', element: <DietaryWelfareEthnicityTab/>},
                            {
                                path: 'forms',
                                element: <StudentFormsTab/>,
                                children: [
                                    {path: '', element: <StudentForms/>},
                                    {
                                        path: ':formRequestId',
                                        element: <SubmitPrivateForm navigateBackRoute={'..'}/>
                                    },
                                ],
                            },
                            {path: 'groups', element: <StudentGroupsTab/>}
                        ],
                    },
                ],
            },
            {
                path: 'forms',
                element: <ProtectedRoute allowedRoles={[ROLES.SCHOOL_ADMIN, ROLES.MEMBER_OF_STAFF]}>
                    <FormsTab/>
                </ProtectedRoute>,
                children: [
                    {
                        path: '',
                        element: <Forms/>,
                    },
                    {
                        path: 'new',
                        element: <FormEditor mode="create" title="New Form"/>,
                    },
                    {
                        path: 'edit/:id',
                        element: <FormEditor mode="edit" title="Edit Form"/>,
                    },
                    {
                        path: 'responses',
                        element: <FormResponsesPage/>,
                        children: [
                            {
                                path: ':id',
                                element: <FormResponses/>,
                            },
                            {
                                path: ':id/:formRequestId',
                                element: <NavigateFromPrivateFormToResponsesPageWrapper/>
                            },
                        ]
                    },
                ],
            },
            {
                path: 'request-and-responses',
                element: <ProtectedRoute
                    allowedRoles={[
                        ROLES.SCHOOL_ADMIN,
                        ROLES.MEMBER_OF_STAFF,
                        ROLES.PARENT,
                        ROLES.STUDENT
                    ]}
                >
                    <RequestAndResponsesTab/>
                </ProtectedRoute>,
                children: [
                    {path: '', element: <RequestAndResponses/>},
                    {
                        path: ':formRequestId',
                        element: <SubmitPrivateForm navigateBackRoute={'/request-and-responses'}/>
                    },
                ],
            },
            {
                path: 'documents',
                element: <ProtectedRoute allowedRoles={[ROLES.SCHOOL_ADMIN, ROLES.MEMBER_OF_STAFF]}>
                    <DocumentsTab/>
                </ProtectedRoute>,
            },
        ],
    },
    {
        path: '/management',
        element: <ProtectedRoute allowedRoles={[ROLES.SUPER_ADMIN]}>
            <SchoolManagementPage/>
        </ProtectedRoute>,
        children: [
            {path: '', element: <SchoolManagementDashboard/>},
            {path: 'new', element: <SchoolSetup mode="create" title="New School"/>},
            {path: 'edit/:id', element: <SchoolSetup mode="edit" title="School Settings"/>},
        ],
    },
    {path: '*', element: <NotFoundPage/>},
    {path: '/submit/result', element: <SubmitPublicFormResult/>},
    {path: '/submit/public/:formId', element: <PublicJotForm/>},
    {path: '/login', element: <LoginPage/>},
    {path: '/password-recovery', element: <ForgotPasswordPage/>},
    {path: '/invite-recovery', element: <InviteRecoveryPage/>},
    {path: '/forms-request/:id', element: <SubmitPublicForm type={FORM_ACCESS_LEVELS.public} navigateBackRoute={'/request-and-responses'}/>},
    {path: '/forms-request/student/:id', element: <SubmitPublicForm type={FORM_ACCESS_LEVELS.publicWithStudentLink} navigateBackRoute={'/request-and-responses'}/>},
    {path: '/forms-request/:id/:encodedData', element: <SubmitPublicForm type={FORM_ACCESS_LEVELS.public} navigateBackRoute={'/request-and-responses'}/>},
    {path: '/forms-request/student/:id/:encodedData', element: <SubmitPublicForm type={FORM_ACCESS_LEVELS.publicWithStudentLink} navigateBackRoute={'/request-and-responses'}/>},
    {path: '/register/:inviteId', element: <RegistrationPage/>},
    {path: '/reset/:requestId', element: <ResetPasswordPage/>},
    {path: '/email/redirect/forms-request/:id', element: <RedirectRoute path="forms-request/:id"/>},
    {path: '/email/redirect/forms-request/student/:id', element: <RedirectRoute path="forms-request/student/:id"/>},
    {path: '/email/redirect/forms-request/:id/:encodedData', element: <RedirectRoute path="forms-request/:id/:encodedData"/>},
    {path: '/email/redirect/forms-request/student/:id/:encodedData', element: <RedirectRoute path="forms-request/student/:id/:encodedData"/>},
    {path: '/email/redirect/register/:id', element: <RedirectRoute path="register/:id"/>},
    {path: '/email/redirect/student/register/:id', element: <RedirectRoute path="student/register/:id"/>},
    {path: '/email/redirect/request-and-responses/:id', element: <RedirectRoute path="request-and-responses/:id"/>},
    {path: '/email/redirect/reset/:id', element: <RedirectRoute path="reset/:id"/>},
    {path: '/email/redirect/login', element: <RedirectRoute path="login"/>},
    {path: '/email/redirect/profiles/:id', element: <RedirectRoute path="profiles/:id"/>},
    {path: '/email/redirect/pdf/download/:id', element: <RedirectRoute path="download/:id"/>}
];

export default routes;
