import useUser from '../../../../utility/hooks/useUser';

const permissions = {
    hide: 0,
    view: 1,
    edit: 2,
};

// eslint-disable-next-line react/prop-types
const PermissionCheck = ({permissionModule, render, returnType = 'readonly'}) => {
    const {user} = useUser();

    const hasPermission = () => {
        if (user.permissions.includes(`Permission.${permissionModule}.Edit`)) {
            return permissions.edit;
        } else if (user.permissions.includes(`Permission.${permissionModule}.View`)) {
            return permissions.view;
        }

        return permissions.hide;
    };

    if (returnType === 'readonly') {
        return {
            [permissions.view]: () => render(true),
            [permissions.edit]: () => render(false),
            [permissions.hide]: () => null
        }[hasPermission()]();
    } else if (returnType === 'isEdit') {
        return hasPermission() === permissions.edit ? render(true) : render(false)
    } else {
        return null;
    }

};

export default PermissionCheck;