import {INVITE_STATUSES} from '../../constants/registrationInviteStatuses';
import {isDateExpired} from '../../utility/dateUtil';
import {apiService} from '../apiService';

export const schoolManagementService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getSchool: builder.query({
                query: (schoolId) => ({
                    url: `/school/${schoolId}`
                }),
            }),
            getSchoolInvitesInfo: builder.query({
                query: (subdomain) => ({
                    url: `/school/${subdomain}/invites-info`
                }),
            }),
            createSchool: builder.mutation({
                query: (body) => ({
                    url: '/school',
                    method: 'POST',
                    data: body,
                })
            }),
            updateSchool: builder.mutation({
                query: (body) => ({
                    url: '/school',
                    method: 'PUT',
                    data: body,
                })
            }),
            getRegistrationInvites: builder.query({
                query: (body) => ({
                    url: '/account/invites',
                    method: 'POST',
                    data: body,
                }),
                transformResponse: (response) => ({
                    ...response,
                    items: response.items.map(item => ({
                        id: item.id,
                        email: item.email,
                        expires: item.expires,
                        status: item.isFailed
                            ? INVITE_STATUSES.failed
                            : item.completedDate
                                ? INVITE_STATUSES.completed
                                : isDateExpired(item.expires)
                                    ? INVITE_STATUSES.expired
                                    : INVITE_STATUSES.pending,
                        completedDate: item.completedDate
                    }))
                }),
                providesTags: (_) => [{type: 'Invites', id: 'LIST'}],
                transformErrorResponse: (_) => ({message: 'Failed to retrieve invites'})
            }),
            sendRegistrationInvite: builder.mutation({
                query: (body) => ({
                    url: '/account/send-invite',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'Invites', id: 'LIST'}],
                transformErrorResponse: (error) => {
                    const defaultErrorMessage = ['Something went wrong. Please try again later.'];
                    const errorMessages = {
                        400: error?.data?.validationErrors
                            ? error.data.validationErrors.map(x => x.errorMessage)
                            : defaultErrorMessage,
                        500: ['Internal server error.']
                    };

                    return errorMessages[error.status] ?? defaultErrorMessage;
                }
            }),
            getPermissions: builder.query({
                query: () => ({
                    url: '/account/permissions/list'
                }),
                providesTags: (_) => [{type: 'Permissions', id: 'LIST'}],
            }),
            getRoles: builder.query({
                query: () => ({
                    url: '/account/roles/list'
                })
            }),
            assignRolePermissions: builder.mutation({
                query: (body) => ({
                    url: '/account/role/assign-permissions',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'Permissions', id: 'LIST'}],
            }),
            getContactInvites: builder.query({
                query: (body) => ({
                    url: '/school/contact-invites',
                    method: 'POST',
                    data: body,
                }),
                providesTags: (_) => ['ContactInvites'],
            }),
            resendInvites: builder.mutation({
                query: (studentIds) => ({
                    url: '/school/contact-invites/resend',
                    method: 'POST',
                    data: {studentIds},
                }),
                invalidatesTags: ['ContactInvites'],
            }),
            getSchoolUsers: builder.query({
                query: (body) => ({
                    url: '/school/users',
                    method: 'POST',
                    data: body,
                }),
                providesTags: (_) => ['SchoolUsers'],
            }),
            updateUserRole: builder.mutation({
                query: (body) => ({
                    url: '/account/role',
                    method: 'PUT',
                    data: body,
                }),
                invalidatesTags: ['SchoolUsers'],
            }),
            getSyncDetails: builder.query({
                query: (body) => ({
                    url: '/school/sync-details',
                    method: 'POST',
                    data: body,
                })
            }),
            getApplicationStatuses: builder.query({
                query: () => ({
                    url: '/school/application-statuses'
                }),
                keepUnusedDataFor: 300
            }),
        }),
});

export const {
    useGetSchoolQuery,
    useGetSchoolInvitesInfoQuery,
    useCreateSchoolMutation,
    useUpdateSchoolMutation,
    useGetRegistrationInvitesQuery,
    useSendRegistrationInviteMutation,
    useGetPermissionsQuery,
    useGetRolesQuery,
    useAssignRolePermissionsMutation,
    useGetContactInvitesQuery,
    useResendInvitesMutation,
    useGetSchoolUsersQuery,
    useUpdateUserRoleMutation,
    useGetSyncDetailsQuery,
    useGetApplicationStatusesQuery
} = schoolManagementService;
