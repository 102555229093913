import React, {useState} from 'react';
import {Box, Button, Checkbox, FormControlLabel, Stack, Typography} from '@mui/material';
import AdmicityGroupFilterSelect from '../../../../shared-components/AdmicityGroupFilterSelect';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import StudentsAutocomplete from '../../Forms/PrivateFormSettings/StudentsAutocomplete';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import IconButton from '@mui/material/IconButton';
import {mergeArraysByKey} from '../../../../utility/mergeArraysByKey';

/* eslint-disable react/prop-types */

const groupFiltersTableColumns = [
    {
        accessorKey: 'displayName',
        header: 'Group Name',
        grow: true,
    },
    {
        accessorKey: 'excluded',
        header: 'Status',
        size: 50,
        grow: false,
        Cell: ({cell}) => cell.getValue() === true ? 'Excluded' : 'Included'
    }
];

const FiltersTab = ({
                        data,
                        settings,
                        strictRecipientsFiltering,
                        setStrictRecipientsFiltering,
                        studentsData,
                        setSettings,
                        disableStrictRecipientsFiltering = false
                    }) => {
    const [filter, setFilter] = useState({});
    const isFilterNotSelected = filter == null || !filter.filterId;
    const rootFilterIds = data ? data.map(x => x.id) : [];

    const handleAddGroupFilter = (isExcluded) => {
        const newFilter = {
            id: filter.filterId,
            displayName: filter.paths.map(x => x.name).join(' - '),
            excluded: isExcluded
        };

        setSettings(prev => ({
            ...prev,
            groupFilters: mergeArraysByKey([...settings.groupFilters, newFilter], settings.groupFilters)
        }));
        setFilter(null);
    };

    return (
        <Box display="flex" flexDirection="column" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2}>
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold">Select Student Groups</Typography>
                    <Typography variant="subtitle2">Choose one or more student groups</Typography>
                </Box>
                <Box>
                    <AdmicityGroupFilterSelect
                        isRequired={!settings.studentFilters.length && !settings.applicationFilters.length}
                        sx={{width: '100%'}}
                        data={data}
                        value={filter?.filterId || ''}
                        onChange={(filterId, paths) => setFilter({filterId, paths})}
                    />
                    <Stack alignItems="center" flexDirection="row" gap={1} justifyContent={'end'} pt={1}>
                        <Button
                            onClick={() => handleAddGroupFilter(true)}
                            variant="outlined"
                            disabled={isFilterNotSelected || rootFilterIds.includes(filter.filterId)}
                        >
                            Exclude
                        </Button>
                        <Button
                            onClick={() => handleAddGroupFilter(false)}
                            variant="contained"
                            disabled={!filter?.filterId}
                        >
                            Include
                        </Button>
                    </Stack>
                </Box>
            </Box>
            {
                settings.groupFilters?.length > 0 && <Box>
                    <AdmicityTableV2
                        columns={groupFiltersTableColumns}
                        data={settings.groupFilters}
                        tableProps={{
                            layoutMode: 'grid-no-grow',
                            enableRowActions: true,
                            enableSorting: false,
                            enableFilters: false,
                            enableHiding: false,
                            enableFullScreenToggle: false,
                            enableColumnResizing: false,
                            enableBottomToolbar: false,
                            enableTopToolbar: false,
                            muiTableBodyProps: {
                                sx: {
                                    '& :last-child td': {
                                        borderBottom: 0,
                                    },
                                }
                            },
                            muiTableContainerProps: {
                                sx: {
                                    maxHeight: 150,
                                    minHeight: 'auto',
                                    border: 'none'
                                }
                            },
                            renderRowActions: ({row}) => (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        const filters = settings.groupFilters.filter(filter => row.original.id !== filter.id);

                                        setSettings(prev => ({
                                            ...prev,
                                            groupFilters: filters
                                        }));
                                    }}>
                                    <DeleteOutlineRoundedIcon fontSize="small"/>
                                </IconButton>
                            )
                        }}
                    />
                    <FormControlLabel
                        sx={{
                            marginBottom: -2,
                        }}
                        disabled={disableStrictRecipientsFiltering}
                        control={
                            <Checkbox
                                checked={strictRecipientsFiltering}
                                onChange={(e) => setStrictRecipientsFiltering(e.target.checked)}
                            />
                        }
                        label="Must appear in all these groups"
                    />
                </Box>
            }
            <Box display="flex" flexDirection="column" gap={2}>
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold">Select Individual Student</Typography>
                    <Typography variant="subtitle2">Choose a specific student</Typography>
                </Box>
                <StudentsAutocomplete
                    label={`Applications${!settings.groupFilters?.length && !settings.studentFilters.length ? ' *' : ''}`}
                    options={studentsData?.applications ?? []}
                    value={settings.applicationFilters}
                    onChange={(e, newValue) => setSettings(prev => ({...prev, applicationFilters: newValue}))}
                    disablePortal={false}
                />
                <StudentsAutocomplete
                    label={`Students${!settings.groupFilters?.length && !settings.applicationFilters.length ? ' *' : ''}`}
                    options={studentsData?.students ?? []}
                    value={settings.studentFilters}
                    onChange={(e, newValue) => setSettings(prev => ({...prev, studentFilters: newValue}))}
                    disablePortal={false}
                />
            </Box>
        </Box>
    );
};

export default FiltersTab;