import {matchRoutes} from 'react-router-dom';

export const getRouteMatchPath = (routes, location) => {
    const matches = matchRoutes(routes, location);

    if (matches?.length) {
        return matches.length === 1
            ? matches[0].route.path
            : matches.map(x => x.route.path)
                .filter(x => x !== '/')
                .join('/');
    }

    return null;
};

export const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    return parts.length >= 2 ? parts[0] : undefined;
};