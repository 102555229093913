import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress} from '@mui/material';
import {useDispatch} from 'react-redux';
import {resetPaginationState} from '../../../hoc/pagination/paginationSlice';
import withPagination from '../../../hoc/pagination/withPagination';
import AdmicityTable from '../../../shared-components/AdmicityTable';
import AdmicityDialog from '../../../shared-components/AdmicityDialog';
import Uploader from '../../SchoolManagement/Setup/Uploader';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import UploadFile from '@mui/icons-material/UploadFile';

import {
    downloadSchoolDocument,
    useDeleteSchoolDocumentMutation,
    useGetSchoolDocumentsQuery,
    useUploadSchoolDocumentsMutation
} from '../../../api/services/filesService';
import useFileDownloader from '../../../utility/hooks/useFileDownloader';

const tableProps = {
    columns: [
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
            sortable: false,
            minWidth: 300,
            flex: 1
        }
    ]
};

const DocumentsTab = () => {
    const dispatch = useDispatch();
    const [openAddDocumentsDialog, setOpenAddDocumentsDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(new Map());
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const [uploadFiles] = useUploadSchoolDocumentsMutation();
    const [deleteFile] = useDeleteSchoolDocumentMutation();

    const Table = withPagination(AdmicityTable, useGetSchoolDocumentsQuery);

    const downloadFile = useFileDownloader();

    useEffect(() => {
        return () => {
            dispatch(resetPaginationState());
        };
    }, []);

    const handleDialogClose = () => {
        setOpenAddDocumentsDialog(false);
        setSelectedFiles(new Map());
        setIsUploading(false);
        setIsUploadSizeExceedsLimit(false);
    };

    const handleFilesUpload = async () => {
        setIsUploading(true);
        await uploadFiles(selectedFiles);
        handleDialogClose();
    };

    const handleFilesSelect = (files) => {
        const newFiles = new Map([...selectedFiles]);

        files.forEach(file => {
            if (!newFiles.has(file.name)) {
                newFiles.set(file.name, file);
            }
        });

        setSelectedFiles(newFiles);
    };

    const handleFileDeleteFromUploadContext = (filename) => {
        const files = new Map([...selectedFiles]);

        files.delete(filename);
        setSelectedFiles(files);
    };

    const handleDeleteFile = async (file) => await deleteFile({documentId: file.id});

    const handleDownloadFile = async (file) => await downloadFile(
        downloadSchoolDocument,
        {documentId: file.id}
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: '100%',
                paddingTop: 2,
            }}
        >
            <div>
                <Button
                    sx={{mb: 2, mt: 2}}
                    variant="contained"
                    startIcon={<UploadFile/>}
                    onClick={() => setOpenAddDocumentsDialog(true)}
                >
                    Add document
                </Button>
            </div>
            <Table
                columns={tableProps.columns}
                rowActions={[
                    {
                        label: 'Delete',
                        action: handleDeleteFile
                    },
                    {
                        label: 'Download',
                        action: handleDownloadFile
                    }
                ]}
                noRowsOverlay={{
                    icon: <InsertDriveFileRoundedIcon/>,
                    text: 'No documents have been added.'
                }}
            />
            <AdmicityDialog
                handleClose={handleDialogClose}
                title={'Add documents'}
                open={openAddDocumentsDialog}
                actions={[
                    {label: 'Cancel', onClick: handleDialogClose},
                    {
                        label: 'Upload',
                        onClick: handleFilesUpload,
                        disabled: (isUploading || selectedFiles.size === 0) || isUploadSizeExceedsLimit,
                        startIcon: isUploading ? <CircularProgress color="inherit" size={16}/> : undefined
                    }
                ]}
                maxWidth={'md'}
            >
                <Uploader
                    totalUploadSizeInMb={25}
                    onFilesSelect={handleFilesSelect}
                    onFileDelete={handleFileDeleteFromUploadContext}
                    onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                    value={Array.from(selectedFiles.values())}
                    readonly={isUploading}
                />
            </AdmicityDialog>
        </Box>
    );
};

export default DocumentsTab;