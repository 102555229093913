import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import PropTypes from 'prop-types';

const RedirectRoute = ({path}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const redirectPath = Object.keys(params).reduce(
        (currentPath, paramKey) => currentPath.replace(`:${paramKey}`, params[paramKey]),
        path
    );
    const queryParams = searchParams.size > 0 ? `?${searchParams.toString()}` : '';

    useEffect(() => {
        navigate(`/${redirectPath}${queryParams}`);
    }, [redirectPath, navigate]);

    return null;
};

RedirectRoute.propTypes = {
    path: PropTypes.string
};

export default RedirectRoute;