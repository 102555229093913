import React, {useEffect} from 'react';
import AdmicityTableV2 from '../../../../../shared-components/Table/V2/AdmicityTableV2';
import {useGetStudentGroupsQuery} from '../../../../../api/services/studentService';
import {useParams} from 'react-router-dom';
import useNotification from '../../../../../utility/hooks/useNotification';

const columns = [
    {
        accessorKey: 'name',
        header: 'Group Name'
    }
];

const StudentGroupsTab = () => {
    const {studentId} = useParams();
    const {data = [], isLoading, isError, error} = useGetStudentGroupsQuery(studentId);
    const {showErrorNotification} = useNotification();

    useEffect(() => {
        if (isError) {
            showErrorNotification(error?.message ?? '');
        }
    }, [isError]);

    return (
        <AdmicityTableV2
            columns={columns}
            data={data}
            isLoading={isLoading}
            tableProps={{
                enableSorting: false,
                enableColumnResizing: false,
                enableRowActions: false,
                enableBottomToolbar: false,
                enableTopToolbar: false,
                muiTableBodyRowProps: {hover: false},
                manualFiltering: false,
                enablePagination: false,
                manualSorting: false,
                enableHiding: false
            }}
        />
    );
};

export default StudentGroupsTab;