import React, { useEffect, useState } from 'react';
import useTextField from '../utility/hooks/useTextField';
import { validateFieldLength, validateGroupFilters } from '../utility/validationUtil';
import { useGetStudentFiltersQuery } from '../api/services/studentService';
import AdmicityDialog from './AdmicityDialog';
import {Box, TextField, MenuItem, Checkbox, FormControlLabel} from '@mui/material';
import PropTypes from 'prop-types';
import FiltersTab from '../pages/SchoolDashboard/Profiles/DownloadReportPopup/FiltersTab';
import findParentNodes from '../utility/groupFilterTreeUtil';

const initialSettings = {
    groupFilters: [],
    studentFilters: [],
    applicationFilters: [],
    groupCategory: null,
    groupType: false,
};

const groupFilterValidationInitialState = { isValid: true };

const CreateOrUpdateGroupDialog = ({
    open,
    title,
    group,
    formCategories,
    onClose,
    onSave,
    groupFilters,
    editMode = false,
}) => {
    const [filtersValidation, setFiltersValidation] = useState(groupFilterValidationInitialState);
    const nameField = useTextField({
        initialValue: group.name ?? '',
        validate: (value) => validateFieldLength(value, 256),
    });

    const {
        data: studentsData = {
            applications: [],
            students: [],
        },
    } = useGetStudentFiltersQuery({ filterId: group.parentGroupFilterId }, { skip: !open });

    const [settings, setSettings] = useState({ ...initialSettings });
    const [strictRecipientsFiltering, setStrictRecipientsFiltering] = useState(false);
    const [isGroupDynamic, setIsGroupDynamic] = useState(false);

    useEffect(() => {
        if (!open) {
            nameField.reset();
            setSettings({ ...initialSettings });
            setFiltersValidation(groupFilterValidationInitialState);
            setStrictRecipientsFiltering(false);
            setIsGroupDynamic(false);
        } else {
            nameField.setValue(group.name ?? '');
            setSettings((prev) => ({
                ...prev,
                groupFilters: group.groupFilters?.filter((filter) => getDisplayName(filter.filterId))
                    .map((filter) => ({
                        id: filter.filterId,
                        displayName: getDisplayName(filter.filterId),
                        excluded: filter.excluded,
                    })) ?? [],
                groupCategory: group.category ?? null,
                groupType: group.groupType ? 'My Group' : 'School Group',
            }));
            setStrictRecipientsFiltering(group.strictRecipientsFiltering);
            setIsGroupDynamic(group.isDynamic)
        }
    }, [open, group]);

    const getDisplayName = (filterId) =>
        findParentNodes(groupFilters, filterId).map((x) => x.name).join(' - ');

    useEffect(() => {
        if (studentsData && group.students?.length > 0) {
            setSettings((prev) => ({
                ...prev,
                applicationFilters: studentsData.applications.filter((x) =>
                    group.students.includes(x.id)
                ),
                studentFilters: studentsData.students.filter((x) => group.students.includes(x.id)),
            }));
        }
    }, [studentsData, group]);

    useEffect(() => {
        setFiltersValidation(
            settings.groupFilters.length > 0
                ? validateGroupFilters(settings.groupFilters)
                : groupFilterValidationInitialState
        );
    }, [settings.groupFilters]);

    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value || null;
        setSettings((prev) => ({
            ...prev,
            groupCategory: selectedCategoryId,
        }));
    };

    const handleGroupTypeChange = (e) => {
        const selectedGroupType = e.target.value;
        setSettings((prev) => ({
            ...prev,
            groupType: selectedGroupType,
        }));
    };

    return (
        <AdmicityDialog
            handleClose={onClose}
            title={title}
            open={open}
            maxWidth="md"
            actions={[
                {
                    label: 'Cancel',
                    onClick: onClose,
                },
                {
                    label: 'Save',
                    disabled: nameField.error || !nameField.value || !filtersValidation.isValid,
                    tooltip:
                        !filtersValidation.isValid && filtersValidation.error
                            ? filtersValidation.error
                            : null,
                    onClick: () =>
                        onSave({
                            ...settings,
                            groupName: nameField.value,
                            strictRecipientsFiltering,
                            formCategoryId: settings.groupCategory,
                            groupType: settings.groupType === 'My Group',
                            isGroupDynamic,
                        }),
                },
            ]}
        >
            <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                    required
                    fullWidth
                    label="Group Name"
                    variant="outlined"
                    margin="normal"
                    {...nameField}
                />
                <TextField
                    select
                    value={settings.groupType}
                    onChange={handleGroupTypeChange}
                    label="Group Type"
                    fullWidth
                    helperText="Please select the type of group (cannot be changed after saving)"
                    disabled={editMode || group.groupType !== undefined}
                >
                    <MenuItem value="My Group">My Group</MenuItem>
                    <MenuItem value="School Group">School Group</MenuItem>
                </TextField>
                <TextField
                    select
                    value={settings.groupCategory || ''}
                    label="Category"
                    helperText="Please select a category or clear the selection"
                    fullWidth
                    onChange={handleCategoryChange}
                    SelectProps={{
                        renderValue: (selected) => {
                            if (!selected) return 'No category selected';
                            const selectedCategory = formCategories.find((category) => category.id === selected);
                            if (!selectedCategory) return 'Invalid category selected';
                            return (
                                <Box display="flex" alignItems="center">
                                    <Box
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            borderRadius: '50%',
                                            backgroundColor: selectedCategory.color || 'grey',
                                            marginRight: 1,
                                        }}
                                    />
                                    {selectedCategory.name || ''}
                                </Box>
                            );
                        },
                    }}
                >
                    <MenuItem value="">
                        <Box display="flex" alignItems="center" sx={{ color: 'grey' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: 'transparent',
                                    marginRight: 1,
                                }}
                            />
                            No category
                        </Box>
                    </MenuItem>
                    {formCategories.length > 0 &&
                        formCategories.map(({ id, name, color }) => (
                            <MenuItem key={id} value={id}>
                                <Box display="flex" alignItems="center">
                                    <Box
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            borderRadius: '50%',
                                            backgroundColor: color || 'grey',
                                            marginRight: 1,
                                        }}
                                    />
                                    {name}
                                </Box>
                            </MenuItem>
                        ))}
                </TextField>
                <FormControlLabel
                    sx={{
                        marginTop: -2,
                        marginBottom: -2,
                    }}
                    disabled={editMode}
                    control={
                        <Checkbox
                            checked={isGroupDynamic}
                            onChange={(e) => setIsGroupDynamic(e.target.checked)}
                        />
                    }
                    label="Dynamic Group (auto-includes students)"
                />
            </Box>
            <FiltersTab
                data={groupFilters}
                settings={settings}
                strictRecipientsFiltering={strictRecipientsFiltering}
                disableStrictRecipientsFiltering={editMode}
                studentsData={studentsData}
                setStrictRecipientsFiltering={setStrictRecipientsFiltering}
                setSettings={setSettings}
            />
        </AdmicityDialog>
    );
};

CreateOrUpdateGroupDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    group: PropTypes.object.isRequired,
    formCategories: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    groupFilters: PropTypes.array.isRequired,
    editMode: PropTypes.bool,
};

export default CreateOrUpdateGroupDialog;
