import React, {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {getRouteMatchPath} from '../../../utility/routeUtil';
import routes from '../../../routes/routes';
import {useDispatch} from 'react-redux';
import {resetTableState} from '../../../store/tableSlice';
import Box from '@mui/material/Box';

const FormsTab = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const path = getRouteMatchPath(routes, pathname);

    useEffect(() => {
        return () => {
            dispatch(resetTableState(path));
        };
    }, [dispatch]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                paddingTop: 2,
            }}
        >
            <Outlet context={{key: path}}/>
        </Box>
    );
};

export default FormsTab;