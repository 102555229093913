import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Box, styled} from '@mui/material';

const FooterStyled = styled(Box)(({theme}) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgb(250, 250, 251)',
}));

const Footer = () =>
    <>
        <FooterStyled>
            <Typography>Admicity © {new Date().getFullYear()}</Typography>
        </FooterStyled>
    </>

export default Footer;