import Typography from '@mui/material/Typography';
import {Box, Button} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {downloadProfileMedicalNoteDocument} from '../../../../../api/services/filesService';
import {FileDownload, UploadFile} from '@mui/icons-material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {getRole} from '../../../../../utility/jwtUtil';
import {ROLES} from '../../../../../constants/roles';
import AdmicityForm from '../../../../../shared-components/AdmicityForm';
import AdmicityDialog from '../../../../../shared-components/AdmicityDialog';
import EditIcon from '@mui/icons-material/Edit';
import Uploader from '../../../../SchoolManagement/Setup/Uploader';

/* eslint-disable react/prop-types */

const MedicalNotes = ({
                          readonly,
                          medicalNotes,
                          downloadFile,
                          studentId,
                          selectedFiles,
                          isUploadSizeExceedsLimit,
                          totalUploadFilesSize,
                          handleSelectFiles,
                          handleFileDeleteFromUploadContext,
                          setIsUploadSizeExceedsLimit,
                          updateMedicalNotes,
                          setTotalUploadFilesSize
                      }) => {
    const {currentSchool, selectedProfile} = useSelector(state => state.profilesInfo);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAddNoteDocumentsDialog, setOpenAddNoteDocumentsDialog] = useState(false);
    const [selectedMedicalNote, setSelectedMedicalNote] = useState(undefined);

    const medicalNoteTextFields = [
        {
            label: 'Summary',
            name: 'summary',
            required: true,
            initialValue: selectedMedicalNote ? selectedMedicalNote.summary : '',
        },
        {
            label: 'Description',
            name: 'note',
            required: false,
            multiline: true,
            initialValue: selectedMedicalNote ? selectedMedicalNote.note : '',
        }
    ];

    const handleDocumentsUploaded = async () => {
        setTotalUploadFilesSize(totalUploadFilesSize - selectedFiles.reduce((total, file) => {
            return total + file.size;
        }, 0) / (1024 * 1024))

        const updatedNote = {
            ...selectedMedicalNote,
            fileName: selectedFiles[0].name,
            file: selectedFiles[0],
            fileId: undefined
        };

        updateMedicalNotes(updatedNote);
        setSelectedMedicalNote(undefined);
        setOpenAddNoteDocumentsDialog(false);
    };

    return (
        <>
            <Typography variant="h5" py={2}>Medical Notes</Typography>
            <Typography>
                Please use this section to add details of any medical condition that does not appear on the above list
                or that has not yet been formally diagnosed. It can also be used to share any other medical information
                that you would like {currentSchool.name} to be aware of that is not related to a specific medical condition or
                medical event. {currentSchool.name} may also update this section during the school day and you will receive an
                email alert to inform you of any updates to {selectedProfile.name}’s Admicity profile.
            </Typography>
            {
                !readonly && <Button
                    variant="contained"
                    sx={{mb: 2, mt: 2, width: 150}}
                    startIcon={<AddCircleOutlineIcon/>}
                    onClick={() => setOpenDialog(true)}
                >
                    Add
                </Button>
            }
            {medicalNotes.filter(e => !e.isDeleted)?.map(note =>
                <>
                    <Box display={'flex'} key={note.summary} justifyContent={'space-between'}>
                        <Box>
                            <Typography fontWeight="bold">{note.summary}</Typography>
                            <Typography>{note.note}</Typography>
                            {!readonly && ((getRole() === ROLES.PARENT && note.uuid) || getRole() !== ROLES.PARENT) && !note.file && !note.fileId &&
                                <Button
                                    sx={{my: 1}}
                                    variant="outlined"
                                    startIcon={<UploadFile/>}
                                    onClick={() => {
                                        setSelectedMedicalNote(note)
                                        setOpenAddNoteDocumentsDialog(true);
                                    }}
                                >
                                    Upload Document
                                </Button>
                            }
                            {!readonly && ((getRole() === ROLES.PARENT && note.uuid) || getRole() !== ROLES.PARENT) &&
                                note.fileName && <Box key={note} display={'flex'} alignItems={'center'}>
                                    <IconButton onClick={() => {
                                        setSelectedMedicalNote(note);
                                        setOpenAddNoteDocumentsDialog(true);
                                    }}>
                                        <UploadFile/>
                                    </IconButton>
                                    <Typography>{note.fileName}</Typography>
                                </Box>
                            }
                        </Box>
                        <Box display={'flex'} sx={{height: '40px'}}>
                            {note.fileId && !note.file &&
                                <IconButton
                                    onClick={async () => {
                                        await downloadFile(downloadProfileMedicalNoteDocument, {
                                            documentId: note.fileId, studentId
                                        })
                                    }}
                                >
                                    <FileDownload/>
                                </IconButton>
                            }
                            {
                                !readonly && ((getRole() === ROLES.PARENT && note.uuid) || getRole() !== ROLES.PARENT) &&
                                <>
                                    <IconButton
                                        onClick={() => {
                                            setSelectedMedicalNote(note);
                                            setOpenDialog(true)
                                        }}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => updateMedicalNotes(note, true)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </>
                            }
                        </Box>
                    </Box>
                </>
            )}
            <AdmicityDialog
                handleClose={() => {
                    setOpenDialog(false);
                    setSelectedMedicalNote(undefined);
                }}
                title={'New Medical Note'}
                open={openDialog}
                actions={[]}
            >
                <AdmicityForm
                    textFields={medicalNoteTextFields}
                    handleSubmit={(data) => {
                        updateMedicalNotes({
                            ...selectedMedicalNote,
                            ...data
                        });
                        setOpenDialog(false);
                        setSelectedMedicalNote(undefined);
                    }}
                    buttonText="Save"
                />
            </AdmicityDialog>

            <AdmicityDialog
                handleClose={() => {
                    setOpenAddNoteDocumentsDialog(false)
                    setSelectedMedicalNote(undefined);
                }}
                title={'Add Documents'}
                open={openAddNoteDocumentsDialog}
                actions={[
                    {
                        label: 'Cancel',
                        onClick: () => {
                            setOpenAddNoteDocumentsDialog(false)
                            setSelectedMedicalNote(undefined);
                        }
                    },
                    {
                        label: 'Upload',
                        onClick: handleDocumentsUploaded,
                        disabled: selectedFiles?.length === 0 || isUploadSizeExceedsLimit,
                    }
                ]}
                maxWidth={'md'}
            >
                <Uploader
                    totalUploadSizeInMb={totalUploadFilesSize}
                    onFilesSelect={handleSelectFiles}
                    onFileDelete={handleFileDeleteFromUploadContext}
                    onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                    singleFile={true}
                    value={selectedFiles}
                />
            </AdmicityDialog>
        </>
    );
};

export default MedicalNotes;