import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    MenuItem,
    Stack,
    Switch,
    TextField
} from '@mui/material';
import FORM_AUDIENCE_TYPE from '../../../../constants/FormAudienceType';
import RegularFormSettings from './RegularFormSettings';
import TriggerFormSettings from './TriggerFormSettings';
import {
    useGetExternalUserFormAssignmentsQuery,
    useGetFormQuestionAssignmentQuery
} from '../../../../api/services/formAssignmentsService';
import {useLazyGetFormQuery} from '../../../../api/services/formsService';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const PrivateFormSettings = (
    {
        formId,
        isFormLive,
        strictRecipientsFiltering,
        allParentsCanSubmit,
        audience,
        audienceOptions,
        settings,
        onSettingsChange,
        onSetStrictRecipientsFiltering,
        onAudienceChange,
        onAllParentsCanSubmitChange
    }) => {
    const [enableTrigger, setEnableTrigger] = useState(false);
    const {
        data: formQuestionAssigment
    } = useGetFormQuestionAssignmentQuery(formId);
    const {data: externalUserFormAssignments} = useGetExternalUserFormAssignmentsQuery(
        {formId},
        {
            skip: audience !== FORM_AUDIENCE_TYPE.externalUsers
        });
    const [getForm] = useLazyGetFormQuery();

    useEffect(() => {
        if (formQuestionAssigment?.id) {
            getForm(formQuestionAssigment.relatedFormId)
                .then(({data: form}) => {
                    const triggerSettings = {
                        id: formQuestionAssigment.id,
                        selectedForm: {
                            id: formQuestionAssigment.relatedFormId,
                            name: form.name,
                            schema: form.schema
                        },
                        relatedComponent: form.schema.find(e => e.id === formQuestionAssigment.relatedComponentId),
                        targetState: formQuestionAssigment.targetState,
                    };

                    onSettingsChange(prev => ({
                        ...prev,
                        triggerFormSettings: {
                            ...triggerSettings,
                            original: triggerSettings
                        }
                    }));
                    setEnableTrigger(true);
                });
        }
    }, [formQuestionAssigment]);

    useEffect(() => {
        if (audienceOptions.length === 1) {
            onAudienceChange(audienceOptions[0].value);
        }
    }, [audienceOptions]);

    const handleAudienceChange = (event) => {
        const newAudienceType = event.target.value;

        if (newAudienceType === FORM_AUDIENCE_TYPE.students) {
            setEnableTrigger(false);
        }

        onAudienceChange(newAudienceType);
    };

    return (
        <Box>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    width: {
                        xs: '100%',
                        sm: '65%',
                        md: '50%',
                        lg: '45%',
                    },
                    padding: 2,
                    gap: 2
                }}
            >
                <TextField
                    select
                    fullWidth
                    label="Audience"
                    value={audience}
                    onChange={handleAudienceChange}
                    disabled={
                        settings && ([
                                ...settings.groupFilters,
                                ...settings?.studentFilters,
                                ...settings?.externalUserEmailAssignments,
                            ].filter(x => x.isDeleted == null || !x.isDeleted).length > 0 ||
                            settings.triggerFormSettings.selectedForm != null)
                    }
                >
                    <MenuItem disabled value={FORM_AUDIENCE_TYPE.none}>None</MenuItem>
                    {
                        audienceOptions
                            .map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))
                    }
                </TextField>
                <FormControl variant="standard">
                    <FormLabel component="legend">When enabled, this form will automatically trigger new forms based on
                        specific responses</FormLabel>
                    <FormGroup>
                        <Stack flexDirection="row" alignItems="center">
                            <FormControlLabel
                                control={<Switch
                                    color="primary"
                                    checked={enableTrigger}
                                    onChange={e => setEnableTrigger(e.target.checked)}
                                    disabled={settings.triggerFormSettings.selectedForm != null ||
                                        audience === FORM_AUDIENCE_TYPE.students ||
                                        audience === FORM_AUDIENCE_TYPE.none}
                                />}
                                label={enableTrigger ? 'Disable Trigger' : 'Enable Trigger'}
                            />
                            {
                                audience === FORM_AUDIENCE_TYPE.students
                                    ? <Tooltip title="Triggered forms are not currently available for students">
                                        <HelpOutlineIcon fontSize="small"/>
                                    </Tooltip>
                                    : null

                            }
                        </Stack>
                    </FormGroup>
                </FormControl>
                {
                    audience === FORM_AUDIENCE_TYPE.parentsForChildCompletion
                        ? <FormControlLabel
                            sx={{
                                gap: 1,
                                marginTop: -1
                            }}
                            control={
                                <Checkbox
                                    sx={{
                                        padding: 0,
                                        marginLeft: 1
                                    }}
                                    checked={allParentsCanSubmit}
                                    onChange={async (e) =>
                                        await onAllParentsCanSubmitChange(e.target.checked)
                                    }
                                    disabled={isFormLive}
                                />
                            }
                            label="Require both parents to complete the form"
                        />
                        : null
                }
            </Box>
            <Divider/>
            <Box paddingTop={2} paddingBottom={2}>
                {
                    audience === FORM_AUDIENCE_TYPE.none
                        ? null
                        : !enableTrigger
                            ? <RegularFormSettings
                                formId={formId}
                                audience={audience}
                                settings={settings}
                                strictRecipientsFiltering={strictRecipientsFiltering}
                                isFormLive={isFormLive}
                                externalUsers={externalUserFormAssignments}
                                onSettingsChange={onSettingsChange}
                                onSetStrictRecipientsFiltering={onSetStrictRecipientsFiltering}
                            />
                            : <TriggerFormSettings
                                formId={formId}
                                formQuestionAssigment={formQuestionAssigment}
                                settings={settings}
                                audience={audience}
                                externalUsers={externalUserFormAssignments}
                                onSettingsChange={onSettingsChange}
                            />
                }
            </Box>
        </Box>
    );
};

PrivateFormSettings.defaultProps = {
    isFormLive: false,
};

PrivateFormSettings.propTypes = {
    formId: PropTypes.number,
    isFormLive: PropTypes.bool,
    strictRecipientsFiltering: PropTypes.bool,
    audience: PropTypes.number,
    audienceOptions: PropTypes.array,
    settings: PropTypes.object,
    allParentsCanSubmit: PropTypes.bool,
    onSettingsChange: PropTypes.func,
    onGroupFiltersChange: PropTypes.func,
    onStudentFiltersChange: PropTypes.func,
    onSetStrictRecipientsFiltering: PropTypes.func,
    onAudienceChange: PropTypes.func,
    onAllParentsCanSubmitChange: PropTypes.func
};

export default PrivateFormSettings;
