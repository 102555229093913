import React, {useMemo} from 'react';
import {CircularProgress, Grid} from '@mui/material';
import {BasicInfoFieldsRenderer} from '../BasicInfoTab/BasicInfoFieldsRenderer';
import AdmicityDialog from '../../../../../shared-components/AdmicityDialog';
import PropTypes from 'prop-types';
import {validateCountry, validateFieldLength, validatePostcode} from '../../../../../utility/validationUtil';
import useTextFields from '../../../../../utility/hooks/useTextFields';

const EditHomeAddressDialog = ({
                                   handleDialogClose,
                                   openEditHomeAddressDialog,
                                   isAddContactInProgress,
                                   isUpdateContactInProgress,
                                   checkTextFieldsAreValid,
                                   handleSaveContactAddress,
                                   selectedContact,
                                   countries
                               }) => {

    const addressTextFields = useMemo(() =>
        selectedContact
            ? [
                {
                    id: 'profile_apartment',
                    label: 'Apartment',
                    name: 'apartment',
                    required: false,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: selectedContact.address?.apartment,
                    dependencies: ['country', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_houseNumber',
                    label: 'House Number',
                    name: 'houseNumber',
                    required: false,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: selectedContact.address?.houseNumber,
                    dependencies: ['country', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_street',
                    label: 'Street',
                    name: 'street',
                    required: false,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: selectedContact.address?.street,
                    dependencies: ['country', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_district',
                    label: 'District',
                    name: 'district',
                    required: false,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: selectedContact.address?.district,
                    dependencies: ['country', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_town',
                    label: 'Town',
                    name: 'town',
                    required: false,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: selectedContact.address?.town,
                    dependencies: ['country', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_houseName',
                    label: 'House Name',
                    name: 'houseName',
                    required: false,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: selectedContact.address?.houseName,
                    dependencies: ['country', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_county',
                    label: 'County',
                    name: 'county',
                    required: false,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: selectedContact.address?.county,
                    dependencies: ['country', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_postcode',
                    label: 'Postcode',
                    name: 'postcode',
                    required: false,
                    validate: (value, fields) => validatePostcode(value, fields.country?.textFieldProps.value),
                    initialValue: selectedContact.address?.postcode,
                    dependencies: ['country'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_country',
                    label: 'Country',
                    name: 'country',
                    type: 'select',
                    select: true,
                    required: false,
                    validate: (value, fields) => validateCountry(value, fields),
                    options: countries.map(element => ({value: element.code, title: element.name})),
                    initialValue: selectedContact.address?.country,
                    dependencies: ['postcode'],
                    nullableOnEmpty: true
                }
            ]
            : [], [selectedContact, openEditHomeAddressDialog]);

    const {
        fields: addressFields,
        getFieldValues: getAddressFieldValues
    } = useTextFields(addressTextFields);

    return (
        <AdmicityDialog
            handleClose={handleDialogClose}
            title={'Edit Home Address'}
            open={openEditHomeAddressDialog}
            actions={[
                {label: 'Cancel', onClick: handleDialogClose},
                {
                    label: 'Save',
                    disabled: isAddContactInProgress || isUpdateContactInProgress ||
                        !checkTextFieldsAreValid(addressFields) ||
                        Object.values(getAddressFieldValues()).every(e => !e),
                    startIcon: (isAddContactInProgress || isUpdateContactInProgress)
                        ? <CircularProgress color="inherit" size={16}/>
                        : undefined,
                    onClick: () => handleSaveContactAddress(getAddressFieldValues())
                }
            ]}
        >
            <Grid container sx={{height: '100%'}} spacing={2}>
                {'apartment' in addressFields && (
                    <Grid item xs={12} sm={6}>
                        <BasicInfoFieldsRenderer fields={{apartment: addressFields.apartment}}/>
                    </Grid>
                )}
                {'houseName' in addressFields && (
                    <Grid item xs={12} sm={6}>
                        <BasicInfoFieldsRenderer fields={{houseName: addressFields.houseName}}/>
                    </Grid>
                )}
                {'houseNumber' in addressFields && (
                    <Grid item xs={12} sm={6}>
                        <BasicInfoFieldsRenderer fields={{houseNumber: addressFields.houseNumber}}/>
                    </Grid>
                )}
                {'street' in addressFields && (
                    <Grid item xs={12} sm={6}>
                        <BasicInfoFieldsRenderer fields={{street: addressFields.street}}/>
                    </Grid>
                )}
                {'district' in addressFields && (
                    <Grid item xs={12} sm={6}>
                        <BasicInfoFieldsRenderer fields={{district: addressFields.district}}/>
                    </Grid>
                )}
                {'town' in addressFields && (
                    <Grid item xs={12} sm={6}>
                        <BasicInfoFieldsRenderer fields={{town: addressFields.town}}/>
                    </Grid>
                )}
                {'county' in addressFields && (
                    <Grid item xs={12} sm={6}>
                        <BasicInfoFieldsRenderer fields={{county: addressFields.county}}/>
                    </Grid>
                )}
                {'postcode' in addressFields && (
                    <Grid item xs={12} sm={6}>
                        <BasicInfoFieldsRenderer fields={{postcode: addressFields.postcode}}/>
                    </Grid>
                )}
                {'country' in addressFields && (
                    <Grid item xs={12}>
                        <BasicInfoFieldsRenderer fields={{country: addressFields.country}}/>
                    </Grid>
                )}
            </Grid>
        </AdmicityDialog>
    );
};

EditHomeAddressDialog.propTypes = {
    handleDialogClose: PropTypes.func.isRequired,
    openEditHomeAddressDialog: PropTypes.bool.isRequired,
    isAddContactInProgress: PropTypes.bool.isRequired,
    isUpdateContactInProgress: PropTypes.bool.isRequired,
    selectedContact: PropTypes.object.isRequired,
    checkTextFieldsAreValid: PropTypes.func.isRequired,
    handleSaveContactAddress: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
};

export default EditHomeAddressDialog;