import React, {useEffect, useMemo, useState} from 'react';
import SchoolSetupForm from '../../SchoolManagement/Setup/SchoolSetupForm';
import SchoolMembersSetup from '../../SchoolManagement/Setup/SchoolMembersSetup';
import AdmicityTabs from '../../../shared-components/AdmicityTabs';
import MisIntegration from './MisIntegration/MisIntegration';
import {Box} from '@mui/material';
import {useGetSchoolQuery, useUpdateSchoolMutation} from '../../../api/services/schoolManagementService';
import useUser from '../../../utility/hooks/useUser';
import AccessSettings from './AccessSettings/AccessSettings';
import ContactInvites from '../../SchoolManagement/Setup/ContactInvites';
import FormCategories from './FormCategories/FormCategories';
import {getSchoolLogo, useDeleteLogoMutation, useUploadLogoMutation} from '../../../api/services/filesService';
import useNotification from '../../../utility/hooks/useNotification';
import SchoolUsers from './SchoolUsers/SchoolUsers';
import SyncLog from './SyncLog/SyncLog';
import Lookups from './Lookups/Lookups';
import GroupFilters from './GroupFilters/GroupFilters';

const SchoolSettings = () => {
    const {user} = useUser();
    const {
        data: schoolInfo = {
            name: '',
            dfeNumber: '',
            notes: '',
            domain: ''
        },
        isError,
        refetch: refetchSchoolInfo
    } = useGetSchoolQuery(user.schoolId, {skip: !user.schoolId});
    const [saveSchool] = useUpdateSchoolMutation();
    const [uploadLogo] = useUploadLogoMutation();
    const [deleteLogo] = useDeleteLogoMutation();
    const [schoolLogo, setSchoolLogo] = useState(null);
    const {showErrorNotification, showSuccessNotification, showDetailedErrorNotification} = useNotification();

    useEffect(() => {
        if (isError) {
            showErrorNotification('Failed retrieve school information');
        }
    }, [isError]);

    useEffect(() => {
        if (schoolInfo?.id) {
            getSchoolLogo(schoolInfo.id)
                .then(r => setSchoolLogo(r.blob));
        }
    }, [schoolInfo]);

    const handleSaveForm = async (payload) => {
        const {logo, ...rest} = payload;

        try {
            await saveSchool(rest).unwrap();
        } catch (e) {
            if (e.data.validationErrors) {
                showDetailedErrorNotification('Failed to save school information', e.data.validationErrors);
            } else {
                showErrorNotification('Something went wrong while saving the school information');
            }

            return;
        }

        try {
            if (!logo) {
                if (schoolLogo) {
                    await deleteLogo().unwrap();
                    setSchoolLogo(null);
                }
            } else {
                if (!schoolLogo || schoolLogo.size !== logo.size || schoolLogo.type !== logo.type) {
                    await uploadLogo(logo).unwrap();
                    const refetchLogoResult = await getSchoolLogo(schoolInfo.id);

                    setSchoolLogo(refetchLogoResult.blob);
                }
            }

            showSuccessNotification('School information saved successfully');
            refetchSchoolInfo();
        } catch (e) {
            showErrorNotification('Something went wrong while saving the school logo');
        }
    };

    const tabs = useMemo(() => [
        {
            label: 'General',
            component: <SchoolSetupForm
                editMode
                schoolInfo={{...schoolInfo, logo: schoolLogo}}
                onSubmit={handleSaveForm}/>
        },
        {
            label: 'MIS Integration',
            component: <MisIntegration/>
        },
        {
            label: 'Users',
            component: <SchoolUsers/>
        },
        {
            label: 'User invites',
            component: <SchoolMembersSetup schoolInfo={schoolInfo}/>
        },
        {
            label: 'Parent Invitations',
            component: <ContactInvites/>
        },
        {
            label: 'Manage access',
            component: <AccessSettings/>
        },
        {
            label: 'Form categories',
            component: <FormCategories/>
        },
        {
            label: 'Sync logs',
            component: <SyncLog/>
        },
        {
            label: 'Lookups',
            component: <Lookups/>
        },
        {
            label: 'Group Filters',
            component: <GroupFilters/>
        }
    ], [schoolInfo, schoolLogo]);

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            flexGrow={1}
            minHeight={'100%'}
        >
            <AdmicityTabs tabs={tabs} variant={'scrollable'}/>
        </Box>
    );
};

export default SchoolSettings;