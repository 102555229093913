import React, {useEffect, useRef} from 'react';
import FormRequest from './FormRequest';
import {useParams, useSearchParams} from 'react-router-dom';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useSubmitPublicFormMutation} from '../../../../api/services/formRequestsService';
import {useGetPublicFormQuery} from '../../../../api/services/formsService';
import {showSnackbar} from '../../../../AppLayout/ApplicationSlice';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {FORM_ACCESS_LEVELS} from '../../../../constants/formAccessLevels';
import objectToFormData from '../../../../utility/objectToFormData';
import {generateUUID} from '../../../../utility/uuidUtil';

const parseData = (encodedData, searchParams) => {
    try {
        if (encodedData) {
            const padding = encodedData.length % 4;
            const base64 = encodedData.replace(/-/g, '+')
                .replace(/_/g, '/')
                .padEnd(encodedData.length + (padding === 0 ? 0 : 4 - padding), '=');

            return JSON.parse(atob(base64));
        } else {
            return {
                t: searchParams.get('t'),
                parent_id: searchParams.get('parent_id')
            };
        }
    } catch (error) {
        return {
            t: '',
            parent_id: ''
        };
    }
};

const SubmitPublicForm = ({navigateBackRoute, type}) => {
    const [searchParams] = useSearchParams();
    const {id, encodedData} = useParams();
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const metadata = parseData(encodedData, searchParams);

    const {data: form, isLoading, error} = useGetPublicFormQuery({
        id,
        type,
        queryParams: {
            t: metadata.t,
            parent_id: metadata.parent_id
        }
    });

    const [submitPublicForm, {
        isSuccess: isFormSubmitSuccess,
        isError: isFormSubmitError,
        error: submitError
    }] = useSubmitPublicFormMutation();

    useEffect(() => {
        if (isFormSubmitError) {
            dispatch(showSnackbar({
                message: submitError,
                severity: 'error'
            }));
        }
    }, [isFormSubmitError]);

    const sendHeight = () => {
        const height = Math.max(
            document.documentElement.scrollHeight,
            document.querySelector('.modal')?.scrollHeight ?? 0
        );

        window.parent.postMessage({
            type: 'iframeResizer',
            iframeHeight: height
        }, '*');
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(sendHeight);
        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const FormSubmittedSuccessfullyView = () =>
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
        >
            <TaskAltRoundedIcon color="success" sx={{fontSize: 64}}/>
            <Typography variant="h5" gutterBottom>Success</Typography>
            <Typography>Form successfully submitted.</Typography>
        </Box>;

    const handleSubmit = async (data) => {
        const responses = {};
        const attachments = [];
        const attachmentKeys = [];

        for (const key in data) {
            if (data[key] instanceof Blob) {
                const file = data[key];
                const fileKey = generateUUID();

                attachments.push(file);
                attachmentKeys.push(fileKey);
                responses[key] = {name: file.name, fileKey};
            } else {
                responses[key] = data[key];
            }
        }

        const payload = type === FORM_ACCESS_LEVELS.publicWithStudentLink
            ? {
                formRequestId: id,
                tag: metadata.t,
                placeholders: JSON.stringify(form.placeholderValues),
                responses: JSON.stringify(responses),
                attachments,
                attachmentKeys,
            }
            : {
                formId: id,
                isCreateApplicantForm: form.schema?.isCreateApplicantForm,
                responses: JSON.stringify(responses),
                attachments,
                attachmentKeys,
            };

        await submitPublicForm(objectToFormData(payload));
    };

    return <div ref={contentRef}>
        {
            isFormSubmitSuccess
                ? <FormSubmittedSuccessfullyView sx={{color: 'theme.palette.success.main'}}/>
                : <FormRequest
                    publicForm
                    formRequest={form}
                    isLoading={isLoading}
                    error={error}
                    onSubmit={handleSubmit}
                    navigateBackRoute={navigateBackRoute}
                />
        }
    </div>;
};

SubmitPublicForm.propTypes = {
    navigateBackRoute: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
};

export default SubmitPublicForm;