import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Tab, Tabs} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {retrieveCurrentSchool, retrieveProfile} from './ProfilesSlice';
import Typography from '@mui/material/Typography';
import {Link, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {tabs} from './const/ProfileDetailTabs';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import useUser from '../../../utility/hooks/useUser';
import {createSelector} from '@reduxjs/toolkit';
import {QueryStatus} from '@reduxjs/toolkit/query';
import {getRouteMatchPath} from '../../../utility/routeUtil';
import routes from '../../../routes/routes';
import {resetTableState} from '../../../store/tableSlice';

const selectQueriesAndMutations = state => ({
    queries: state.api.queries,
    mutations: state.api.mutations,
});

const selectIsSomeQueryPending = createSelector(
    [selectQueriesAndMutations],
    ({queries, mutations}) => {
        for (const query of Object.values(queries)) {
            if (query.status === QueryStatus.pending) {
                return true;
            }
        }

        for (const mutation of Object.values(mutations)) {
            if (mutation.status === QueryStatus.pending) {
                return true;
            }
        }

        return false;
    }
);

const ProfileDetails = () => {
    const selectedProfile = useSelector(state => state.profilesInfo.selectedProfile);
    const updatingSuccessful = useSelector(state => state.profilesInfo.updatingSuccessful);
    const {studentId} = useParams();
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user} = useUser();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [profileTabs, setProfileTabs] = useState([]);
    const isSomeQueryPending = useSelector(selectIsSomeQueryPending);
    const {pathname} = useLocation();
    const path = getRouteMatchPath(routes, pathname);

    useEffect(() => {
        dispatch(retrieveCurrentSchool());
        setProfileTabs(tabs.filter(tab => tab.shouldRender(user.permissions)));
        return () => {
            dispatch(resetTableState(path));
        };
    }, [dispatch]);

    useEffect(() => {
        if (profileTabs.length > 0) {
            const index = profileTabs.findIndex(tab => location.includes(tab.route));
            const tabIndex = index === -1 ? 0 : index;

            setCurrentTabIndex(tabIndex);
            navigate(profileTabs[tabIndex].route);
        }
    }, [profileTabs.length]);

    useEffect(() => {
        dispatch(retrieveProfile({studentId}));
    }, [updatingSuccessful]);

    const handleChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
        navigate(profileTabs[newValue].route);
    };

    const buildConfirmMessage = (profile) => {
        return profile.needsConfirmation?.length !== 0
            ? 'Details that still require confirmation: ' + profile.needsConfirmation
            : '';
    };

    return (
        <>
            <Box>
                <Button
                    variant="text"
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={() => navigate('/profiles')}
                >
                    Back
                </Button>
                <Typography variant="h4" py={2}>Personal Details
                    for {selectedProfile.name} {selectedProfile.surname}</Typography>
                <Typography variant="h7" color="red" py={2}>{buildConfirmMessage(selectedProfile)}</Typography>
            </Box>
            {
                user.permissions
                    ? <Tabs
                        value={currentTabIndex}
                        onChange={handleChange}
                        variant="scrollable"
                        textColor="inherit"
                        allowScrollButtonsMobile
                    >
                        {
                            profileTabs.map((tab, index) => (
                                <Tab
                                    key={index}
                                    tabIndex={index}
                                    label={tab.label}
                                    component={Link}
                                    to={tab.route}
                                />
                            ))
                        }
                    </Tabs>
                    : ''
            }
            <Box
                position="relative"
                minHeight={40}
                marginTop={2}
                display="flex"
                flexGrow={1}
                flexDirection="column"
            >
                <Outlet context={{key: path}}/>
                {isSomeQueryPending && profileTabs[currentTabIndex]?.showLoadingSpinner && (
                    <>
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            width="100%"
                            height="100%"
                            sx={{
                                backgroundColor: 'rgb(250, 250, 251)',
                                zIndex: (theme) => theme.zIndex.drawer,
                                pointerEvents: 'none',
                            }}
                        />
                        <Box
                            position="fixed"
                            top="50%"
                            left="50%"
                            sx={{
                                transform: 'translate(-50%, -50%)',
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                                pointerEvents: 'none'
                            }}
                        >
                            <CircularProgress size={40} />
                        </Box>
                    </>
                )}
            </Box>
        </>);
};

export default ProfileDetails;
